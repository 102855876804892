import React, { type ReactNode } from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';
import type { CmdbObjectMain as IssueCmdbObjectFieldType } from './main.tsx';
import type { Props as AsyncIssueCmdbObjectFieldProps } from './types.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const AsyncLazyIssueCmdbObjectField = lazy<typeof IssueCmdbObjectFieldType>(() =>
	import(/* webpackChunkName: "async-cmdb-object-inline-edit-view" */ './main').then(
		(module) => module.CmdbObjectMain,
	),
);
export const AsyncIssueCmdbObjectField = (props: AsyncIssueCmdbObjectFieldProps) => (
	<ErrorBoundary id="issue-field-cmdb-object" packageName="jira-issue-field-cmdb-object">
		<Placeholder name="issue-field-cmdb-object-field" fallback={<></>}>
			<AsyncLazyIssueCmdbObjectField {...props} />
		</Placeholder>
	</ErrorBoundary>
);

export type IssueCmdbObjectFieldBoundaryProps = {
	children: ReactNode;
	packageName: string;
};

export const IssueCmdbObjectFieldBoundary = ({
	children,
	packageName,
}: IssueCmdbObjectFieldBoundaryProps) => (
	<JSErrorBoundary fallback="flag" id="issue-cmdb-object-field" packageName={packageName}>
		{children}
	</JSErrorBoundary>
);

export const AsyncIssueCmdbObjectFieldWithBoundary = (props: AsyncIssueCmdbObjectFieldProps) => (
	<IssueCmdbObjectFieldBoundary packageName="issue-cmdb-object">
		<AsyncIssueCmdbObjectField {...props} />
	</IssueCmdbObjectFieldBoundary>
);
