import React, { memo, useMemo, useState, type ReactNode } from 'react';
import { styled } from '@compiled/react';
import { parseISO } from 'date-fns';
import AkCommitIcon from '@atlaskit/icon/core/commit';
import BitbucketCommitsIcon from '@atlaskit/icon/glyph/bitbucket/commits';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import AkChevronDownIcon from '@atlaskit/icon/utility/chevron-down';
import { colors } from '@atlaskit/theme';
import { fontFallback } from '@atlaskit/theme/typography';
import { token } from '@atlaskit/tokens';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { ISSUE } from '@atlassian/jira-development-board-common/src/common/constants.tsx';
import { DevInfoTypes } from '@atlassian/jira-development-board-common/src/types.tsx';
import { AsyncDevInfoIcon } from '@atlassian/jira-development-board-dev-info-icon/src/async.tsx';
import DevInfoIcon from '@atlassian/jira-development-board-dev-info-icon/src/main.tsx';
import { useLocaleTimeZone } from '@atlassian/jira-development-common/src/common/utils/hooks/use-locale-timezone/index.tsx';
import RelativeDate from '@atlassian/jira-development-common/src/ui/relative-date/index.tsx';
import { SummaryItem } from '@atlassian/jira-development-summary-common/src/ui/summary-item/index.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { FormattedI18nMessage } from '@atlassian/jira-formatted-i18n-message/src/ui/index.tsx';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import {
	FireUiAnalytics,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import type { Commit as CommitType } from '../common/types.tsx';
import { CreateCommitDropdown } from './create-commit-dropdown/index.tsx';
import { CreateCommitIconButton } from './create-commit-icon-button/index.tsx';
import messages from './messages.tsx';

const COMMIT_SUMMARY_ITEM_TEST_ID = 'development-summary-commit.ui.summary-item';

// eslint-disable-next-line jira/styled/styled-component-order, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconWrapper = styled.span({
	verticalAlign: 'middle',
});

const CommitIconOld = (
	<IconWrapper aria-hidden>
		<BitbucketCommitsIcon size="medium" label="" />
	</IconWrapper>
);

const CommitIcon = (
	<IconWrapper aria-hidden>
		<AkCommitIcon color={token('color.link')} label="" />
	</IconWrapper>
);
type CreateCommitSummaryItemProps = {
	hasCommitCapabilities: boolean;
	shouldForceVisibleSecondary: boolean;
	setShouldForceVisibleSecondary: (arg1: ((arg1: boolean) => boolean) | boolean) => void;
};
const CreateCommitSummaryItem = ({
	shouldForceVisibleSecondary,
	setShouldForceVisibleSecondary,
	hasCommitCapabilities,
}: CreateCommitSummaryItemProps) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const downChevron = useMemo(
		() =>
			isVisualRefreshEnabled() ? (
				<AkChevronDownIcon
					color={token('color.link')}
					label={formatMessage(messages.createCommitDropdownIconLabel)}
				/>
			) : (
				<ChevronDownIcon
					size="medium"
					label={formatMessage(messages.createCommitDropdownIconLabel)}
				/>
			),
		[formatMessage],
	);

	const commonSummaryItemProps = {
		title: formatMessage(messages.createCommitTitleLowercase),
		linkFormatting: true,
		icon: isVisualRefreshEnabled() ? CommitIcon : CommitIconOld,
	};

	return hasCommitCapabilities ? (
		<>
			<FireUiAnalytics
				key="viewed button createDevAction"
				action="viewed"
				actionSubject="button"
				actionSubjectId="createDevAction"
				attributes={{ inline: false, summaryItemType: 'commit' }}
			/>
			<CreateCommitDropdown
				onOpenDropdown={({ isOpen }) => {
					setShouldForceVisibleSecondary(isOpen);
				}}
				getDropdownTrigger={({ ref, onClick, ...props }) => (
					<SummaryItem
						secondaryData={downChevron}
						forceVisibleSecondary={shouldForceVisibleSecondary}
						reference={ref}
						{...commonSummaryItemProps}
						{...props}
						onClick={() => {
							onClick();
							fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'createDevAction', {
								inline: false,
								summaryItemType: 'commit',
							});
						}}
						data-testid={COMMIT_SUMMARY_ITEM_TEST_ID}
					/>
				)}
			/>
		</>
	) : null;
};
type Props = {
	hasCommitCapabilities: boolean;
	commit: CommitType | null | undefined;
	onClick?: () => void;
	issueId: string;
	issueKey: string;
};

export const areEqual = (previousProps: Props, nextProps: Props): boolean =>
	nextProps.commit?.count === previousProps.commit?.count &&
	nextProps.commit?.lastUpdated === previousProps.commit?.lastUpdated &&
	nextProps.commit?.url === previousProps.commit?.url;

const CommitOld = memo<Props>(
	({ onClick, commit, hasCommitCapabilities, issueId, issueKey }: Props) => {
		const { formatNumber, formatMessage } = useIntl();
		const { locale, timeZone } = useLocaleTimeZone();
		const [shouldForceVisibleSecondary, setShouldForceVisibleSecondary] = useState(false);

		const { count = 0, lastUpdated } = commit ?? {};
		const title = useMemo(
			() => (
				<FormattedI18nMessage
					componentsMapping={{
						bold: BoldFont,
					}}
					message={formatMessage(messages.title, {
						count: formatNumber(count),
						boldStart: '{boldStart}',
						boldEnd: '{boldEnd}',
					})}
				/>
			),
			[formatMessage, formatNumber, count],
		);
		const relativeDate = useMemo(
			() => (
				<RelativeDateContainer>
					{lastUpdated !== null && lastUpdated !== undefined ? (
						<RelativeDate date={parseISO(lastUpdated)} locale={locale} timeZone={timeZone} strict />
					) : (
						<></>
					)}
				</RelativeDateContainer>
			),
			[lastUpdated, locale, timeZone],
		);

		const summaryItemComponent = (commitInput: CommitType) => (
			<SummaryItem
				title={title}
				relativeDate={relativeDate}
				onClick={onClick}
				itemType="commit"
				itemCount={commitInput.count}
				oneClickUrl={commitInput.url}
				oneClickToolTipContent={formatMessage(messages.tooltip)}
				secondaryHover={
					<CreateCommitDropdown
						getDropdownTrigger={(props) => <CreateCommitIconButton {...props} />}
						onOpenDropdown={({ isOpen }) => {
							setShouldForceVisibleSecondary(isOpen);
						}}
					/>
				}
				forceVisibleSecondary={shouldForceVisibleSecondary}
				data-testid={COMMIT_SUMMARY_ITEM_TEST_ID}
			/>
		);

		if (!ff('devops-popups-on-issue-view_zikxe')) {
			return commit && count > 0 ? (
				summaryItemComponent(commit)
			) : (
				<CreateCommitSummaryItem
					hasCommitCapabilities={hasCommitCapabilities}
					shouldForceVisibleSecondary={shouldForceVisibleSecondary}
					setShouldForceVisibleSecondary={setShouldForceVisibleSecondary}
				/>
			);
		}

		if (commit && count > 0) {
			if (fg('convert_devinfoicon_to_be_synchronous')) {
				return (
					<DevInfoIcon
						devInfoType={DevInfoTypes.COMMIT}
						issueId={Number(issueId)}
						customTrigger={summaryItemComponent(commit)}
						scopeId={`devInfoPanelApp${issueKey}`}
						placement="bottom-end"
						offset={[-8, 8]}
						zIndex={layers.modal}
						location={ISSUE}
					/>
				);
			}
			return (
				<Placeholder fallback={summaryItemComponent(commit)} name="async-commit-dev-info-icon">
					<AsyncDevInfoIcon
						devInfoType={DevInfoTypes.COMMIT}
						issueId={Number(issueId)}
						customTrigger={summaryItemComponent(commit)}
						scopeId={`devInfoPanelApp${issueKey}`}
						placement="bottom-end"
						offset={[-8, 8]}
						zIndex={layers.modal}
						location={ISSUE}
					/>
				</Placeholder>
			);
		}

		return (
			<CreateCommitSummaryItem
				hasCommitCapabilities={hasCommitCapabilities}
				shouldForceVisibleSecondary={shouldForceVisibleSecondary}
				setShouldForceVisibleSecondary={setShouldForceVisibleSecondary}
			/>
		);
	},
	areEqual,
);

export const CommitEmptyState = () => {
	const { formatMessage } = useIntl();

	const icon = isVisualRefreshEnabled() ? CommitIcon : CommitIconOld;
	return (
		<SummaryItem
			icon={icon}
			disabled
			title={formatMessage(messages.emptyStateUpdate)}
			data-testid={COMMIT_SUMMARY_ITEM_TEST_ID}
		/>
	);
};

const CommitNew = memo<Props>((props: Props) => (
	<UFOSegment name="development-summary-commit">
		<CommitOld {...props} />
	</UFOSegment>
));

const Commit = componentWithFG(
	'jira-add-ufo-instrument-for-asyncdevinfoicon',
	CommitNew,
	CommitOld,
);

export default Commit;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BoldFont = styled.span<{ children: ReactNode }>({
	fontWeight: 500,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RelativeDateContainer = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
	font: token('font.body.UNSAFE_small', fontFallback.body.UNSAFE_small),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N200),
});
