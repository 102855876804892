import React, { type ComponentPropsWithoutRef } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { borderRadius } from '@atlassian/jira-common-styles/src/main.tsx';
import {
	FIELD_HEADING_TITLE_COMPONENT_SELECTOR,
	FIELD_WRAPPER_COMPONENT_SELECTOR,
	MULTILINE_FIELD_HEADING_TITLE_COMPONENT_SELECTOR,
} from '@atlassian/jira-issue-field-heading/src/styled.tsx';
import { issueViewLayers as layers } from '@atlassian/jira-issue-view-layers/src/index.tsx';
import type { RequiredComponents, StickyHeaderProps } from '../types.tsx';

const groupBorderRadius = borderRadius + 1;

export const configButtonSelectorName = 'jira-issue-view-layout-group-common-ui-config-button';
const CONFIG_BUTTON_COMPONENT_SELECTOR = `[data-component-selector="${configButtonSelectorName}"]`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ConfigButton = styled(Button)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	display: 'none !important',
	marginRight: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	padding: '0 !important',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	svg: {
		marginBottom: token('space.negative.025', '-2px'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const IconWrapper = styled.div({
	minWidth: '24px',
	marginBottom: token('space.negative.050', '-4px'),
});

const titleStyledSelectorName = 'jira-issue-view-layout-group-common-ui-title-styled';
export const TITLE_STYLED_COMPONENT_SELECTOR = `[data-component-selector="${titleStyledSelectorName}"]`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TitleStyledComponent = styled.h2({
	letterSpacing: 'inherit',
	padding: `0 ${token('space.050', '4px')} 0 0`,
	color: token('color.text.subtle', '#44546F'),
	font: token('font.body'),
	fontWeight: token('font.weight.medium'),
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	maxWidth: '60%',
	flexShrink: 0,
});

const TitleStyled = (props: ComponentPropsWithoutRef<typeof TitleStyledComponent>) => (
	<TitleStyledComponent data-component-selector={titleStyledSelectorName} {...props} />
);

const subTitleStyledSelectorName = 'jira-issue-view-layout-group-common-ui-sub-title-styled';
export const SUB_TITLE_STYLED_COMPONENT_SELECTOR = `[data-component-selector="${subTitleStyledSelectorName}"]`;

// Issue View Fit and Finish: This 1px change will be kept until the font changes as using space tokens or otherwise misaligns the title and subtitle text
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SubTitleStyledComponent = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N300),
	font: token('font.body.UNSAFE_small'),
	paddingRight: token('space.100', '8px'),
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	paddingTop: '1px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'@-moz-document url-prefix()': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		'&&': {
			marginBottom: token('space.negative.025', '-2px'),
		},
	},
});

const SubTitleStyled = (props: ComponentPropsWithoutRef<typeof SubTitleStyledComponent>) => (
	<SubTitleStyledComponent data-component-selector={subTitleStyledSelectorName} {...props} />
);

const innerPaddingStyle = {
	padding: `${token('space.100', '8px')} ${token('space.150', '12px')} ${token('space.100', '8px')} ${token('space.150', '12px')}`,
	boxSizing: 'border-box',
	[`${FIELD_HEADING_TITLE_COMPONENT_SELECTOR}, ${MULTILINE_FIELD_HEADING_TITLE_COMPONENT_SELECTOR}`]:
		{
			color: token('color.text.subtle', colors.N500),
		},
	[FIELD_WRAPPER_COMPONENT_SELECTOR]: {
		marginBottom: token('space.150', '12px'),
	},
	[`> div > ${FIELD_WRAPPER_COMPONENT_SELECTOR}:last-child`]: {
		marginBottom: 0,
	},
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeadingRightAlignedStyled = styled.div({
	marginLeft: 'auto',
	display: 'flex',
});

const borderStyle = `1px solid ${token('color.border', colors.N40)}`;
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const GroupStyled = styled.details({
	boxSizing: 'border-box',
	marginBottom: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&[open] > div': {
		borderTop: 0,
		borderRight: borderStyle,
		borderBottom: borderStyle,
		borderLeft: borderStyle,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		borderBottomRightRadius: `${groupBorderRadius}px`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		borderBottomLeftRadius: `${groupBorderRadius}px`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:not([open]) > div': {
		display: 'none', // required for cypress tests compatibility
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&[open] > summary': {
		borderBottomRightRadius: 0,
		borderBottomLeftRadius: 0,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const InnerBodyPadding = styled.div(innerPaddingStyle);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const SummaryWithPadding = styled.summary(innerPaddingStyle);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledInnerHeaderPadding = styled(SummaryWithPadding)<StickyHeaderProps>({
	display: 'flex',
	paddingTop: token('space.150', '12px'),
	paddingBottom: token('space.150', '12px'),
	height: '48px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${groupBorderRadius}px`,
	borderTop: borderStyle,
	border: borderStyle,
	alignItems: 'center',
	position: 'sticky',

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	top: ({ stickyHeaderPosition }) => (stickyHeaderPosition ? `${stickyHeaderPosition}px` : 0),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: `${token('elevation.surface.overlay', colors.N0)}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: layers.groupStickyHeader,
	// all conditions to make button visible
	'&:hover, &:focus, &:focus-within': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('elevation.surface.overlay.hovered', colors.N20),
		cursor: 'pointer',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		[CONFIG_BUTTON_COMPONENT_SELECTOR]: {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
			display: 'block !important',
		},
	},
	'&:active': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('elevation.surface.overlay.pressed', colors.B50),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		borderColor: token('color.border', colors.B300),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		[`${SUB_TITLE_STYLED_COMPONENT_SELECTOR}, ${TITLE_STYLED_COMPONENT_SELECTOR}, svg`]: {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			color: `${token('color.text', colors.B300)} !important`,
		},
	},

	'&::-webkit-details-marker': {
		display: 'none',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InnerHeaderPadding = styled(StyledInnerHeaderPadding)({
	'&:focus': {
		outline: `${token('border.width.outline', '2px')} solid ${token(
			'color.border.focused',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			colors.B200,
		)}`,
		outlineOffset: '-2px',
	},
});

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const defaultComponents = {
	Title: TitleStyled,
	SubTitle: SubTitleStyled,
	HeaderRightAligned: HeadingRightAlignedStyled,
	Header: InnerHeaderPadding,
	Body: InnerBodyPadding,
	Group: GroupStyled,
} as RequiredComponents;
