/** @jsx jsx */
import React, { useMemo } from 'react';
import { jsx } from '@compiled/react';
import Button from '@atlaskit/button/new';
import Heading from '@atlaskit/heading';
import Image from '@atlaskit/image';
import Link from '@atlaskit/link';
import { Box, Inline, Stack, Text, xcss } from '@atlaskit/primitives';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import illustrationDark from '../assets/illustration-dark.svg';
import illustration from '../assets/illustration.svg';
import illustrationDarkGa from '../assets/illustrationDarkForGa.svg';
import illustrationGa from '../assets/illustrationForGa.svg';
import { messages } from './messages.tsx';

const PinFieldDiscoverabilityBanner = ({
	onButtonClick,
	onBannerDismiss,
	isPinFieldObservabilityExpEnabled = false,
}: {
	onButtonClick: () => void;
	onBannerDismiss: () => void;
	isPinFieldObservabilityExpEnabled: boolean;
}) => {
	const { formatMessage } = useIntl();

	const altText = formatMessage(messages.altText);
	const altTextForPinFieldsGA = formatMessage(messages.altTextForPinFieldsGa);

	const memoizedFormattedMessage = useMemo(
		() => (
			<FormattedMessage
				{...messages.pinIconsMessageObservationExperiment}
				values={{
					link: (chunks) => (
						<Link
							href="https://support.atlassian.com/jira-software-cloud/docs/pin-a-field-to-the-top-of-an-issue/"
							target="_blank"
						>
							{chunks}
						</Link>
					),
				}}
			/>
		),
		[],
	);

	const renderBanner = () => {
		if (isPinFieldObservabilityExpEnabled) {
			return (
				<Image src={illustrationGa} srcDark={illustrationDarkGa} alt={altTextForPinFieldsGA} />
			);
		}
		return <Image src={illustration} srcDark={illustrationDark} alt={altText} />;
	};

	return (
		<Box xcss={pinFieldDiscoverabilityButton} tabIndex={0}>
			<Stack space="space.150">
				{renderBanner()}
				<Stack space="space.050">
					<Heading as="h1" size="xsmall">
						{formatMessage(messages.heading)}
					</Heading>
					<Text size="medium">
						{isPinFieldObservabilityExpEnabled ? (
							<>{memoizedFormattedMessage}</>
						) : (
							<>{formatMessage(messages.content)}</>
						)}
					</Text>
					{isPinFieldObservabilityExpEnabled ? (
						<Inline alignInline="end">
							<Button
								appearance="subtle"
								onClick={onBannerDismiss}
								aria-label={formatMessage(messages.close)}
							>
								{formatMessage(messages.close)}
							</Button>
						</Inline>
					) : (
						<Inline xcss={mainStyles} space="space.050" alignInline="end">
							<Button
								appearance="subtle"
								onClick={onBannerDismiss}
								aria-label={formatMessage(messages.dismiss)}
							>
								{formatMessage(messages.dismiss)}
							</Button>
							<Button onClick={onButtonClick} aria-label={formatMessage(messages.pinFields)}>
								{formatMessage(messages.pinFields)}
							</Button>
						</Inline>
					)}
				</Stack>
			</Stack>
		</Box>
	);
};
export default PinFieldDiscoverabilityBanner;

const pinFieldDiscoverabilityButton = xcss({
	paddingBottom: 'space.050',
	paddingTop: 'space.050',
});

const mainStyles = xcss({
	marginTop: 'space.100',
});
