import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';

export const NUM_INITIAL_ITEMS_TO_LOAD = 5;
export const NUM_INITIAL_ITEMS_TO_LOAD_FOR_POST_LOAD_ACTIONS = 100;
export const NUM_PAGED_ITEMS_TO_LOAD = 100;
export const NUM_PAGED_ITEMS_TO_LOAD_OLD = 10;

export const activityFeedTopMargin = `${3 * gridSize}px`;

export const LOAD_NEWER_BUTTON = 'LOAD_NEWER_BUTTON' as const;
export const LOAD_OLDER_BUTTON = 'LOAD_OLDER_BUTTON' as const;

export type LOAD_COMMENTS_BUTTON = typeof LOAD_NEWER_BUTTON | typeof LOAD_OLDER_BUTTON;
