import { EMBEDDED_CONFLUENCE_MODE, parseUrl } from '@atlassian/embedded-confluence-common';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import {
	ConfluenceContentTypes,
	DisplayTypes,
	type ConfluenceData,
	type EmbeddedConfluenceState,
	type OpenSidePanelActionParams,
	type SetIsLoadingForDeferSpaceSelectionActionParams,
} from './types.tsx';
import { convertUrlByContentState } from './utils.tsx';

export const initialState: EmbeddedConfluenceState = {
	confluenceData: {
		contentId: '',
		contentUrl: '',
		contentData: null,
		mode: EMBEDDED_CONFLUENCE_MODE.VIEW_MODE,
		isDraft: false,
		isLive: false,
	},
	contentActions: {},
	contentType: ConfluenceContentTypes.PAGE,
	embeddedConfluenceSource: '',
	displayType: DisplayTypes.PANEL,
	panel: {
		initialWidth: 0,
		isOpen: false,
		isLoading: false,
		isExpanded: false,
	},
	modal: {
		isOpen: false,
	},
};

export const openSidePanel =
	({
		contentId,
		contentType,
		embeddedConfluenceSource,
		data,
		displayType,
		initialWidth,
		hostname,
		isDraft,
		isLive,
		isExpanded,
		mode,
		onLinkPage,
		onPublish,
		shouldOpenModal,
		url,
		title,
	}: OpenSidePanelActionParams) =>
	({ setState }: StoreActionApi<EmbeddedConfluenceState>) => {
		setState({
			confluenceData: {
				contentId,
				contentUrl: url,
				contentData: data,
				hostname,
				mode,
				isDraft,
				isLive,
				title,
				spaceKey: parseUrl(url)?.spaceKey,
			},
			contentActions: {
				onLinkPage,
				onPublish,
			},
			embeddedConfluenceSource,
			contentType,
			displayType,
			panel: {
				initialWidth,
				isOpen: !shouldOpenModal,
				isExpanded,
			},
			modal: {
				isOpen: shouldOpenModal,
			},
		});
	};

export const closeSidePanel =
	() =>
	({ setState }: StoreActionApi<EmbeddedConfluenceState>) => {
		setState(initialState);
	};

export const setDeferredSpaceData =
	(deferredSpaceName?: string, deferredSpaceKey?: string) =>
	({ setState, getState }: StoreActionApi<EmbeddedConfluenceState>) => {
		const { confluenceData, ...prevState } = getState();

		setState({
			confluenceData: {
				...confluenceData,
				deferredSpaceName,
				spaceKey: deferredSpaceKey || confluenceData.spaceKey, // If deferredSpaceKey is not provided, use the current spaceKey.
			},
			...prevState,
		});
	};

export const setIsLoadingForDeferSpaceSelection =
	({ isLoading, isDeferSpaceSelection }: SetIsLoadingForDeferSpaceSelectionActionParams) =>
	({ setState, getState }: StoreActionApi<EmbeddedConfluenceState>) => {
		const { panel, ...prevState } = getState();

		setState({
			...prevState,
			isDeferSpaceSelection,
			panel: {
				...panel,
				isLoading,
			},
		});
	};

export const toggleExpandedState =
	() =>
	({ setState, getState }: StoreActionApi<EmbeddedConfluenceState>) => {
		const { panel, confluenceData, ...prevState } = getState();
		const isCurrentlyExpanded = panel.isExpanded;
		const { mode, isDraft, contentUrl, spaceKey } = confluenceData;
		setState({
			panel: {
				...panel,
				isExpanded: !isCurrentlyExpanded,
			},
			confluenceData: {
				contentUrl: convertUrlByContentState({ mode, isDraft, contentUrl, spaceKey }),
			},
			...prevState,
		});
	};

export const setIsDraftState =
	({ newIsDraftState }: { newIsDraftState: boolean }) =>
	({ setState, getState }: StoreActionApi<EmbeddedConfluenceState>) => {
		const { confluenceData, ...prevState } = getState();
		setState({
			confluenceData: {
				...confluenceData,
				isDraft: newIsDraftState,
			},
			...prevState,
		});
	};

export const setConfluenceData =
	(newData: ConfluenceData) =>
	({ setState, getState }: StoreActionApi<EmbeddedConfluenceState>) => {
		const { confluenceData, ...prevState } = getState();
		setState({
			confluenceData: {
				...confluenceData,
				...newData,
			},
			...prevState,
		});
	};

export const actions = {
	closeSidePanel,
	openSidePanel,
	setDeferredSpaceData,
	setIsDraftState,
	setIsLoadingForDeferSpaceSelection,
	toggleExpandedState,
	setConfluenceData,
} as const;
