import React, { useState, useRef } from 'react';
import Placeholder from '@atlaskit/react-ufo/placeholder';
import { fg } from '@atlassian/jira-feature-gating';
import type { MainIssueAggQueryRelayFragment } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/main.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type { LOAD_COMMENTS_BUTTON } from '@atlassian/jira-issue-view-common-constants/src/activity-feed.tsx';
import type { CommentsPageInfo } from '@atlassian/jira-issue-shared-types/src/common/types/comment-transformer-types.tsx';
import Comments from './comments-view.tsx';

type Props = {
	rootRelayFragment?: MainIssueAggQueryRelayFragment | null;
};

const CommentsViewWrapper = ({ rootRelayFragment }: Props) => {
	const [initialPaginationParams, setInitialPaginationParams] = useState<{
		pageInfo: CommentsPageInfo;
		buttonType: LOAD_COMMENTS_BUTTON;
	}>();

	const fetcherRef = useRef<{
		getNextComments: (pageInfo: CommentsPageInfo, buttonType: LOAD_COMMENTS_BUTTON) => void;
	}>();

	const getNewComments = (pageInfo: CommentsPageInfo, buttonType: LOAD_COMMENTS_BUTTON) => {
		if (initialPaginationParams && fetcherRef.current) {
			fetcherRef.current.getNextComments(pageInfo, buttonType);
		}

		if (!initialPaginationParams) {
			const params = { pageInfo, buttonType };
			setInitialPaginationParams(params);
		}
	};

	return (
		<>
			<Comments
				getNewComments={getNewComments}
				{...(fg('smart-replies-system-setting') ? { rootRelayFragment } : {})}
			/>
			{initialPaginationParams && (
				<Placeholder name="CommentsFetcher" fallback={null}>
					<LazyCommentsFetcher
						initialPageInfo={initialPaginationParams.pageInfo}
						initialButtonType={initialPaginationParams.buttonType}
						fetcherRef={fetcherRef}
					/>
				</Placeholder>
			)}
		</>
	);
};

const LazyCommentsFetcher = lazyForPaint(
	() => import(/* webpackChunkName: "async-comments-fetcher" */ './ui/comments-fetcher.tsx'),
);

export default CommentsViewWrapper;
