import { PACKAGE_NAME_GASV3 as FORGE_UI_ANALYTICS_SOURCE_NAME } from '@atlassian/jira-forge-ui-analytics/src/constants.tsx';
import { type UIAnalyticsEvent, getEvent } from '@atlassian/jira-product-analytics-bridge';
import type { MyEvent } from '@atlassian/jira-product-analytics-bridge/src/utils/get-event-for-web-client';

/**
 * @returns True if the edit event originated from issue view
 */
export const useIsEditFromIssueView = (event: UIAnalyticsEvent) => {
	const ISSUE_VIEW_SOURCES = [
		'fullIssueScreen',
		'boardIssueViewScreen',
		'agility-backlogScreen',
		'issueNavigatorEmbeddedIssueViewScreen',
		'globalIssueNavigatorIssueScreen',
		'mobileIssueScreen',
		'classicRoadmapScreen',
		'roadmapScreen',
		'versionDetailsIssueScreen',
		'bitbucketIssueScreen',
		'jsmQueueAgentViewSidebarScreen',
		'viewQueueIssueScreen',
		'jswModalIssueAppScreen',
		'jwmModalIssueAppScreen',
		'jswSidebarIssueAppScreen',
		'jwmSidebarIssueAppScreen',
	];

	const {
		payload: { source },
	} = // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		getEvent(event) as MyEvent;

	if (!source) {
		return false;
	}

	if (ISSUE_VIEW_SOURCES.includes(source)) {
		// event originated from issue view location
		return true;
	}

	if (source.startsWith(FORGE_UI_ANALYTICS_SOURCE_NAME)) {
		// event originated from forge app, which can only be mounted in issue view for field editing
		return true;
	}

	return false;
};
