import React from 'react';
import Placeholder from '@atlaskit/react-ufo/placeholder';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import type { Area } from '@atlassian/jira-issue-view-common-types/src/connect-field-type.tsx';
import type { issueViewEntitlementField_servicedeskCustomerServiceCustomFields_EntitlementFieldProductSelector_fragmentKey$key } from '@atlassian/jira-relay/src/__generated__/issueViewEntitlementField_servicedeskCustomerServiceCustomFields_EntitlementFieldProductSelector_fragmentKey.graphql';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type { EntitlementFieldProductSelector } from './ui/issue-view-entitlement-field/index.tsx';
import type OrganizationField from './ui/issue-view-organization-field/index.tsx';

const LazyOrganizationField = lazyForPaint<typeof OrganizationField>(
	() =>
		import(
			/* webpackChunkName: "async-customer-service-custom-fields-organization-field" */ './ui/issue-view-organization-field/index.tsx'
		),
);

export const AsyncOrganizationField = (props: {
	area?: Area;
	// fragmentKey: issueViewEntitlementField_servicedeskCustomerServiceEntitlementsField_EntitlementsField$key;
}) => {
	return (
		<ErrorBoundary id="asyncOrganizationField" packageName="servicedeskCustomerServiceCustomFields">
			<Placeholder name="async-customer-service-custom-fields-organization-field" fallback={null}>
				<LazyOrganizationField {...props} />
			</Placeholder>
		</ErrorBoundary>
	);
};

const LazyEntitlementField = lazyForPaint<typeof EntitlementFieldProductSelector>(() =>
	import(
		/* webpackChunkName: "async-customer-service-custom-fields-entitlement-field" */ './ui/issue-view-entitlement-field/index.tsx'
	).then((module) => module.EntitlementFieldProductSelector),
);

type EntitlementFieldProps = {
	area?: Area;
	fragmentKey: issueViewEntitlementField_servicedeskCustomerServiceCustomFields_EntitlementFieldProductSelector_fragmentKey$key;
};

export const AsyncEntitlementField = (props: EntitlementFieldProps) => {
	return (
		<ErrorBoundary id="asyncEntitlementField" packageName="servicedeskCustomerServiceCustomFields">
			<Placeholder name="async-customer-service-custom-fields-entitlement-field" fallback={null}>
				<LazyEntitlementField {...props} />
			</Placeholder>
		</ErrorBoundary>
	);
};
