import React from 'react';

import { Stack, Box, xcss } from '@atlaskit/primitives';

const RecentRuleRunsSkeleton: React.FC = () => (
	<Stack
		space="space.075"
		testId="issue-view-layout-templates-views.ui.context.visible-hidden.ui.automation-issue-audit-log-panel.automation-issue-audit-log-body.recent-rule-runs.recent-rule-runs-skeleton.recent-rule-runs-skeleton"
	>
		<Box>
			<Box xcss={iconLoadingStyle} />
			<Box xcss={ruleNameLoadingLongStyle} />
			<Box xcss={dateLoadingStyle} />
		</Box>
		<Box>
			<Box xcss={iconLoadingStyle} />
			<Box xcss={ruleNameLoadingShortStyle} />
			<Box xcss={dateLoadingStyle} />
		</Box>
		<Box>
			<Box xcss={iconLoadingStyle} />
			<Box xcss={ruleNameLoadingLongStyle} />
			<Box xcss={dateLoadingStyle} />
		</Box>
		<Box>
			<Box xcss={iconLoadingStyle} />
			<Box xcss={ruleNameLoadingShortStyle} />
			<Box xcss={dateLoadingStyle} />
		</Box>
	</Stack>
);

const iconLoadingStyle = xcss({
	display: 'inline-block',
	width: '16px',
	height: '16px',
	borderRadius: '3px',
	backgroundColor: 'color.skeleton',
});

const ruleNameLoadingShortStyle = xcss({
	marginLeft: 'space.050', // should be 5px
	display: 'inline-block',
	height: '16px',
	width: '140px',
	borderRadius: '3px',
	backgroundColor: 'color.skeleton',
});

const ruleNameLoadingLongStyle = xcss({
	marginLeft: 'space.050', // should be 5px
	display: 'inline-block',
	height: '16px',
	width: '200px',
	borderRadius: '3px',
	backgroundColor: 'color.skeleton',
});

const dateLoadingStyle = xcss({
	marginLeft: 'space.250', // should be 21px
	paddingTop: 'space.025',
	height: '12px',
	width: '80px',
	borderRadius: '3px',
	backgroundColor: 'color.skeleton',
});

export default RecentRuleRunsSkeleton;
