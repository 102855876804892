/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { relativeFontSizeToBase16 } from '@atlaskit/editor-shared-styles';
import { N40, N50 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

type Props = {
	placeholder?: string;
	onClick?: () => void;
	onFocus?: () => void;
	onChange?: () => void;
	isLoading?: boolean;
	hiddenBorder?: boolean;
};

export const CommentTextAreaPlaceholder = ({
	placeholder,
	onClick,
	isLoading,
	hiddenBorder,
}: Props) => {
	const { formatMessage } = useIntl();

	return (
		<button
			data-testid="canned-comments.common.ui.comment-text-area-placeholder.textarea"
			css={[textAreaStyles, hiddenBorder && styles]}
			onClick={onClick}
			aria-busy={isLoading ? true : undefined}
		>
			{placeholder || formatMessage(messages.defaultPlaceholderMessages)}
		</button>
	);
};

// NOTE: Styling gets copied from platform/packages/editor/editor-core/src/ui/ChromeCollapsed/styles.ts and adjusted to the design
// Except the focus visible state which is new
const textAreaFontSize = relativeFontSizeToBase16(14);
const textAreaStyles = css({
	appearance: 'none',
	resize: 'none',
	// NOTE: before changing this.
	// see jira/src/packages/jira-canned-comments/src/ui/canned-comments-placeholder/index.tsx:71
	backgroundColor: `var(--jira-canned-comments-background-color, ${token('color.background.input')})`,
	borderWidth: '1px',
	borderStyle: 'solid',
	borderColor: `${token('color.border.input', N40)}`,
	borderRadius: token('border.radius', '3px'),
	boxSizing: 'border-box',
	height: '90px',
	paddingLeft: token('space.250', '20px'),
	paddingTop: token('space.150', '12px'),
	paddingBottom: token('space.150', '12px'),
	font: token('font.body', textAreaFontSize),
	width: '100%',
	fontWeight: 400,
	lineHeight: 1.42857142857143,
	letterSpacing: '-0.005em',
	color: token('color.text.subtlest'),
	display: 'inline-flex',
	alignItems: 'flex-start',
	'&:hover': {
		// NOTE: before changing this.
		// see jira/src/packages/jira-canned-comments/src/ui/canned-comments-placeholder/index.tsx:71
		backgroundColor: `var(--jira-canned-comments-background-color, ${token('color.background.input.hovered')})`,
		borderColor: token('color.border.input', N50),
		cursor: 'text',
	},
	'&:focus': {
		outline: 'none',
	},
	'&:focus-visible': {
		outline: `2px solid ${token('color.border.focused')}`,
		outlineOffset: token('space.025', '2px'),
	},
});
const styles = css({ borderWidth: '0' });
