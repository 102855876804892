import React, { type ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { styled } from '@compiled/react';
import sortBy from 'lodash/sortBy';
import Avatar from '@atlaskit/avatar';
import { IconTile } from '@atlaskit/icon';
import BranchIcon from '@atlaskit/icon/core/branch';
import AkSettingsIcon from '@atlaskit/icon/core/settings';
import SettingsIcon from '@atlaskit/icon/glyph/settings';
import { BitbucketIcon } from '@atlaskit/logo';
import { LinkItem, MenuGroup, Section } from '@atlaskit/menu';
import { ResizingHeight } from '@atlaskit/motion';
import Popup from '@atlaskit/popup';
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { fontFallback } from '@atlaskit/theme/typography';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { layers, gridSize, fontFamily } from '@atlassian/jira-common-styles/src/main.tsx';
import type { DropdownTriggerParams } from '@atlassian/jira-development-summary-common/src/common/types.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import { useIssueId, useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useIssueTypeField } from '@atlassian/jira-issue-field-issue-type/src/services/index.tsx';
import { useSummaryField } from '@atlassian/jira-issue-field-summary-common/src/services/index.tsx';
import {
	ContextualAnalyticsData,
	fireUIAnalytics,
	FireUiAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { useProjectKey } from '@atlassian/jira-project-context-service/src/main.tsx';
import { DevSummaryCreateBranchPopupSkeleton } from '@atlassian/jira-skeletons/src/ui/dev-summary-create-branch-popup/DevSummaryCreateBranchPopupSkeleton.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { GIT_COMMAND_SECTION_TEST_ID } from '../../common/constants.tsx';
import type { CreateBranchTarget } from '../../common/types.tsx';
import { useBranchCommandBuilderStore } from '../../controllers/branch-command-builder-store/index.tsx';
import BranchCommandBuilder from './copy-branch-command/branch-command-builder/main.tsx';
import CopyBranchCommand from './copy-branch-command/main.tsx';
import messages from './messages.tsx';

export type Props = {
	getDropdownTrigger: (props: DropdownTriggerParams) => ReactElement;
	createBranchTargets: CreateBranchTarget[];
	hideCreateBranchLinks?: boolean;
	onOpenDropdown?: (arg1: { isOpen: boolean }) => void;
};

const getAvatarSrc = (iconUrl: null | string, iconClass: null | string) => {
	if (iconUrl !== null) return <Avatar appearance="square" size="xsmall" src={iconUrl} />;

	if (iconClass === 'bitbucket') {
		return (
			<Box>
				<BitbucketIcon appearance="brand" size="small" />
			</Box>
		);
	}

	return (
		<Box xcss={boxStyles}>
			<IconTile icon={BranchIcon} label="" appearance="blueBold" shape="square" size="16" />
		</Box>
	);
};

export const useIsDropdownOpen = (isOpen = false) => useState(isOpen);

const CreateBranchDropdownInner = ({
	createBranchTargets,
	onOpenDropdown,
	getDropdownTrigger,
	hideCreateBranchLinks = false,
}: Props) => {
	const { formatMessage } = useIntl();

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [isOpen, setIsOpen] = useIsDropdownOpen();
	const [isBranchConfigOpen, setIsBranchConfigOpen] = useState(false);
	const previousOpen = useRef(false);
	const [{ isLoading }, { getUserConfigBranchData, setBranchData }] =
		useBranchCommandBuilderStore();

	const accountId = useAccountId();
	const issueKey = useIssueKey();
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const issueId = ff('relay-migration-issue-fields-summary_eog1v') ? useIssueId() : undefined;
	const [{ value: issueType }] = useIssueTypeField({ issueKey });
	const [{ value: issueSummary }] = useSummaryField({ issueKey, issueId });
	const projectKey = useProjectKey(issueKey);

	const handleOnClick = () => {
		const analyticsEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'updateBranchNameFormatButton',
		});

		fireUIAnalytics(analyticsEvent);

		setIsBranchConfigOpen(true);
	};

	const handleOnHover = () => {
		const analyticsEvent = createAnalyticsEvent({
			action: 'hovered',
			actionSubject: 'updateBranchNameFormatButton',
		});

		fireUIAnalytics(analyticsEvent);
	};

	useEffect(() => {
		if (previousOpen.current !== isOpen) {
			previousOpen.current = isOpen;
			if (isOpen) {
				fireUIAnalytics(createAnalyticsEvent({}), 'dropdown opened', 'createBranchDropdown');
				setBranchData({
					issueKey,
					issueSummary: issueSummary || '',
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					issueType: (issueType?.name as string) || '',
					projectKey,
				});
				getUserConfigBranchData(accountId);
			} else {
				fireUIAnalytics(createAnalyticsEvent({}), 'dropdown closed', 'createBranchDropdown');
				isBranchConfigOpen && setIsBranchConfigOpen(false);
			}
			onOpenDropdown && onOpenDropdown({ isOpen });
		}
	}, [
		createAnalyticsEvent,
		createBranchTargets,
		isOpen,
		onOpenDropdown,
		accountId,
		getUserConfigBranchData,
		setBranchData,
		issueKey,
		issueSummary,
		issueType?.name,
		projectKey,
		isBranchConfigOpen,
	]);

	const createBranchDropdownItems = useMemo(
		() =>
			sortBy(createBranchTargets, 'name').map(
				({ iconClass, iconUrl, name: remoteAppName, url: remoteAppUrl }) =>
					remoteAppUrl !== null &&
					remoteAppUrl !== undefined && (
						<LinkItem
							// eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
							cssFn={() => ({
								minHeight: 'initial',
							})}
							key={remoteAppUrl}
							href={remoteAppUrl}
							onClick={() => {
								setIsOpen(false);
								fireUIAnalytics(createAnalyticsEvent({}), 'link clicked', 'scmCreateBranchLink', {
									redirectToScmProviderPage: true,
								});
							}}
							iconBefore={getAvatarSrc(iconUrl, iconClass)}
							target="_blank"
							rel="noreferrer noopener"
							testId="development-summary-branch.ui.create-branch-dropdown.link-item"
						>
							{formatMessage(messages.createBranchMessage, { remoteAppName })}
						</LinkItem>
					),
			),
		[createBranchTargets, formatMessage, createAnalyticsEvent, setIsOpen],
	);

	const showCreateBranchLinkSection = !hideCreateBranchLinks && createBranchTargets.length > 0;

	return (
		<Popup
			id="create-branch-dropdown"
			zIndex={layers.modal + 1}
			placement="bottom-end"
			shouldFlip
			trigger={(triggerProps) =>
				getDropdownTrigger({
					...triggerProps,
					isSelected: isOpen,
					onClick: () => setIsOpen(!isOpen),
				})
			}
			isOpen={isOpen}
			onClose={() => {
				setIsOpen(false);
				setIsBranchConfigOpen(false);
			}}
			content={() => (
				<BranchDropdownWidthContainer data-testid="development-summary-branch.ui.create-branch-dropdown.branch-dropdown-width-container">
					<ResizingHeight>
						{(props) => (
							<div {...props}>
								{isBranchConfigOpen ? (
									<BranchCommandBuilder setIsBranchConfigOpen={setIsBranchConfigOpen} />
								) : (
									<MenuGroup testId="development-summary-branch.ui.create-branch-dropdown.menu-group">
										{showCreateBranchLinkSection && (
											<Section
												testId="development-summary-branch.ui.create-branch-dropdown.create-source-branch-section"
												title={formatMessage(messages.branchTargetsGroupTitle)}
											>
												<BranchDropdownScrollContainer>
													{createBranchDropdownItems}
												</BranchDropdownScrollContainer>
												<FireUiAnalytics action="shown" actionSubject="createBranchLinkList" />
											</Section>
										)}
										<Section
											hasSeparator={showCreateBranchLinkSection}
											testId={GIT_COMMAND_SECTION_TEST_ID}
										>
											{isLoading ? (
												<DevSummaryCreateBranchPopupSkeleton />
											) : (
												<>
													<GitCommandSectionHeader>
														<GitCommandTitle data-testid="development-summary-branch.ui.create-branch-dropdown.git-command-title">
															{formatMessage(messages.gitCommandGroupTitle)}
														</GitCommandTitle>
														<Tooltip
															content={formatMessage(messages.configurableBranchCreateButtonLabel)}
															position="top"
															testId="development-summary-branch.ui.create-branch-dropdown.tooltip"
														>
															<ConfigButton
																showCreateBranchLinkSection
																onClick={handleOnClick}
																onMouseEnter={handleOnHover}
																data-testid="development-summary-branch.ui.create-branch-dropdown.config-button"
															>
																{isVisualRefreshEnabled() ? (
																	<AkSettingsIcon
																		color={token('color.icon')}
																		label={formatMessage(
																			messages.configurableBranchCreateButtonLabel,
																		)}
																	/>
																) : (
																	<SettingsIcon
																		label={formatMessage(
																			messages.configurableBranchCreateButtonLabel,
																		)}
																		size="small"
																	/>
																)}
															</ConfigButton>
														</Tooltip>
													</GitCommandSectionHeader>
													<FauxDropdownItem>
														<CopyBranchCommand />
													</FauxDropdownItem>
												</>
											)}
										</Section>
									</MenuGroup>
								)}
							</div>
						)}
					</ResizingHeight>
				</BranchDropdownWidthContainer>
			)}
		/>
	);
};

export const CreateBranchDropdown = ({
	createBranchTargets,
	onOpenDropdown,
	getDropdownTrigger,
	hideCreateBranchLinks = false,
}: Props) => {
	const scmProviderTypes = useMemo(
		() => createBranchTargets.map((target) => target.applicationType),
		[createBranchTargets],
	);

	return (
		<ContextualAnalyticsData
			attributes={{
				scmProviderTypes,
				singleScmProvider: createBranchTargets.length === 1,
			}}
		>
			<CreateBranchDropdownInner
				createBranchTargets={createBranchTargets}
				onOpenDropdown={onOpenDropdown}
				getDropdownTrigger={getDropdownTrigger}
				hideCreateBranchLinks={hideCreateBranchLinks}
			/>
		</ContextualAnalyticsData>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FauxDropdownItem = styled.div({
	padding: `${token('space.050', '4px')} ${token('space.250', '20px')} ${token(
		'space.100',
		'8px',
	)} ${token('space.250', '20px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BranchDropdownScrollContainer = styled.div({
	maxHeight: '145px',
	overflow: 'auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BranchDropdownWidthContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * 48}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ConfigButton = styled.button<{ showCreateBranchLinkSection: Boolean }>({
	padding: token('space.050', '4px'),
	border: 'none',
	borderRadius: '4px',
	backgroundColor: 'transparent',
	cursor: 'pointer',
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('color.background.neutral.hovered', colors.N30),
		transition: 'background-color 0.1s ease-out',
	},
	transition: 'all 0.1s ease-out',
	lineHeight: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const GitCommandSectionHeader = styled.div({
	padding: `0 ${token('space.250', '20px')}`,
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const GitCommandTitle = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	fontFamily,
	textTransform: 'uppercase',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
	font: token('font.body.small', fontFallback.body.small),
	fontWeight: token('font.weight.bold', 'bold'),
	margin: `${token('space.100', '8px')} 0`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle', colors.N300),
});

const boxStyles = xcss({
	backgroundColor: 'elevation.surface.overlay',
	height: token('space.250', '20px'),
	width: token('space.250', '20px'),
	borderRadius: 'border.radius.100',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
});
