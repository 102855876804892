import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { FormattedMessage } from '@atlassian/jira-intl';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import messages from './messages.tsx';
import type { BaseError, Props } from './types.tsx';

const getInstanceLink = (error: BaseError) => <a href={error.instanceUrl}>{error.instanceName}</a>;

export const InstanceErrorMessage = (props: Props) => {
	const { formatMessage } = useIntl();
	const errorInstancesOneMessage = (errors: BaseError[]) => (
		<FormattedMessage
			{...messages.oneInstanceError}
			values={{
				instanceOne: getInstanceLink(errors[0]),
			}}
		/>
	);

	const errorInstancesTwoMessage = (errors: BaseError[]) => (
		<FormattedMessage
			{...messages.twoInstanceError}
			values={{
				instanceOne: getInstanceLink(errors[0]),
				instanceTwo: getInstanceLink(errors[1]),
			}}
		/>
	);

	const errorInstancesThreeMessage = (errors: BaseError[]) => (
		<FormattedMessage
			{...messages.threeInstanceError}
			values={{
				instanceOne: getInstanceLink(errors[0]),
				instanceTwo: getInstanceLink(errors[1]),
				instanceThree: getInstanceLink(errors[2]),
			}}
		/>
	);

	const errorInstancesMoreMessage = (errors: BaseError[]) => (
		<FormattedMessage
			{...messages.moreInstanceError}
			values={{
				instanceOne: getInstanceLink(errors[0]),
				instanceTwo: getInstanceLink(errors[1]),
				instanceThree: getInstanceLink(errors[2]),
				count: errors.length - 3,
			}}
		/>
	);

	const renderErrorDescription = (errors: BaseError[]) => {
		switch (errors.length) {
			case 1:
				return errorInstancesOneMessage(errors);
			case 2:
				return errorInstancesTwoMessage(errors);
			case 3:
				return errorInstancesThreeMessage(errors);
			default:
				return errorInstancesMoreMessage(errors);
		}
	};

	const { errors } = props;

	return errors && errors.length ? (
		<div>
			<Title>{formatMessage(messages.connectionTitle)}</Title>
			{renderErrorDescription(errors)}
		</div>
	) : null;
};
export default InstanceErrorMessage;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Title = styled.div({
	margin: `${token('space.300', '24px')} 0 ${token('space.100', '8px')} 0`,
	fontWeight: 500,
});
