import React from 'react';

import { defineMessages, useIntl } from '@atlassian/jira-intl';

import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { ContextPanelGroup } from '../context-group/main.tsx';
import automationIconLegacy from './assets/automation-icon-legacy.svg';
import GenericError from './generic-error/index.tsx';

import AutomationIssueAuditLogBody from './automation-issue-audit-log-body/index.tsx';

/**
 * An admittedly, hacky way, to leverage the same UI that connect context panel uses.
 */
const CONTEXT_PANEL_GROUP_HARDCODED_PROPS = {
	icon: { url: automationIconLegacy },
	status: null,
	groupId: 'automation-issue-audit-log-panel',
	extension: undefined,
} as const;

const messages = defineMessages({
	panelTitle: {
		id: 'automation-issue-audit-log-panel.panel-title',
		defaultMessage: 'Automation',
		description:
			"The title of the panel. It's always shown and indicates that the panel is about Automation. This panel can be expanded/collapsed. The experience within is about showing automation rules which have been ran and have affected this issue.",
	},
	panelSubtitle: {
		id: 'automation-issue-audit-log-panel.panel-subtitle',
		defaultMessage: 'Rule executions',
		description:
			'This is text shown next to the title when the panel is minimised. It gives a brief description of what the panel is about. Then panel is about showing automation rule executions which have acted on this issue.',
	},
});

const useI18nContextPanelAutomationProps = () => {
	const { formatMessage } = useIntl();
	return {
		...CONTEXT_PANEL_GROUP_HARDCODED_PROPS,
		title: formatMessage(messages.panelTitle),
		subtitle: formatMessage(messages.panelSubtitle),
	};
};

const AutomationIssueAuditLogPanel: React.FC = () => {
	const accountId: string | null = useAccountId();
	const isSignedIn = accountId !== null;

	const automationPanelContextPanelProps = useI18nContextPanelAutomationProps();

	const shouldShow = isSignedIn;
	if (!shouldShow) {
		return null;
	}

	return (
		<UFOSegment name="issue-automation-audit-log-panel">
			<ErrorBoundary id="automation-issue-audit-log-panel">
				<ContextPanelGroup {...automationPanelContextPanelProps}>
					<JSErrorBoundary id="automation-issue-audit-log-panel-body" fallback={GenericError}>
						<AutomationIssueAuditLogBody />
					</JSErrorBoundary>
				</ContextPanelGroup>
			</ErrorBoundary>
		</UFOSegment>
	);
};

export default AutomationIssueAuditLogPanel;
