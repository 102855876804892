import React, { type ReactElement, useState, useCallback } from 'react';
import Popup, { type TriggerProps } from '@atlaskit/popup';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { FFCleanupError, JobStatus } from '../../types.tsx';
import { CreatePrDropdownMenuContent } from './create-pr-dropdown-content/index.tsx';

export type DropdownTriggerParams = TriggerProps & {
	onClick: () => void;
	isSelected: boolean;
};

export type Props = {
	getDropdownTrigger: (props: DropdownTriggerParams) => ReactElement;
	status?: JobStatus;
	ffcleanupError?: FFCleanupError;
	issueAri: string;
	isEligible: boolean;
	eligibilityFailureMessages?: string[];
	setStatus: (value: JobStatus | undefined) => void;
};

export const CreatePrDropdown = ({
	issueAri,
	getDropdownTrigger,
	status,
	setStatus,
	isEligible,
	eligibilityFailureMessages,
	ffcleanupError,
}: Props) => {
	const [isOpen, setIsOpen] = useState(false);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onClickHandler = () => {
		const event = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'dropdown',
		});
		const openStatus = !isOpen ? 'open' : 'close';
		fireUIAnalytics(event, `autodev-create-draft-pr-dropdown-${openStatus}`);
		setIsOpen(!isOpen);
	};

	const PopupContent = useCallback(
		() => (
			<CreatePrDropdownMenuContent
				issueAri={issueAri}
				isEligible={isEligible}
				eligibilityFailureMessages={eligibilityFailureMessages}
				status={status}
				setStatus={setStatus}
				ffcleanupError={ffcleanupError}
			/>
		),
		[status, issueAri, isEligible, setStatus, eligibilityFailureMessages, ffcleanupError],
	);

	return (
		<Popup
			id="create-draft-pr-dropdown"
			zIndex={layers.modal + 1}
			placement="bottom-end"
			shouldFlip
			trigger={(triggerProps) =>
				getDropdownTrigger({
					...triggerProps,
					isSelected: isOpen,
					onClick: onClickHandler,
				})
			}
			isOpen={isOpen}
			onClose={() => setIsOpen(false)}
			content={PopupContent}
		/>
	);
};
