import type { Flag } from '@atlassian/jira-flags';
import { APP_IDENTIFIER } from '../../common/constants.tsx';
import messages from './messages.tsx';

export const AI_COMMENTS_EXPERIENCE = {
	experience: 'AISuggestedComments',
	experienceId: 'fetchAISuggestedComments',
	analyticsSource: APP_IDENTIFIER,
};

export const AI_COMMENTS_METRIC_KEY = `${APP_IDENTIFIER}.fetch-ai-suggested-comments`;
export const AI_COMMENTS_HISTOGRAM = {
	result: '1000_2000_3000_4000_5000_10000',
	response: '1000_2000_3000_4000_5000_10000',
};

export const FETCH_SUGGESTED_COMMENTS_FAILED_FLAG_ID = 'fetch-suggested-comments-failed-flag';
export const FETCH_SUGGESTED_COMMENTS_FAILED_FLAG: Flag = {
	id: FETCH_SUGGESTED_COMMENTS_FAILED_FLAG_ID,
	type: 'error',
	isAutoDismiss: true,
	title: messages.fetchErrorFlagTitle,
	description: messages.fetchErrorFlagDescription,
};
