import transformToISOString from '@atlassian/jira-common-date/src/utils/transform-to-iso-string/index.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import {
	type NormalizedComment,
	type NormalizedLoadedComments,
	type NormalizedUsers,
	type CommentVisibility,
	COMMENT_VISIBILITY_TYPE_GROUP,
	COMMENT_VISIBILITY_TYPE_ROLE,
} from '@atlassian/jira-issue-gira-transformer-types/src/common/types/comments.tsx';
import type { Comment } from '@atlassian/jira-issue-gira-transformer-types/src/common/types/server-data.tsx';
import type {
	RawComment,
	RawComments,
	RawUser,
	CommentsPageInfo,
} from '@atlassian/jira-issue-shared-types/src/common/types/comment-transformer-types.tsx';
import {
	JIRA_COMMENT_VISIBILITY_TYPE_GROUP,
	JIRA_COMMENT_VISIBILITY_TYPE_ROLE,
} from '@atlassian/jira-issue-shared-types/src/common/types/comment-type.tsx';
import { COMMENT_VISIBILITY_PUBLIC } from '@atlassian/jira-issue-view-common-types/src/comment-type.tsx';
import type { JiraCommentVisibility } from '@atlassian/jira-issue-view-common-types/src/gira.tsx';
import { FETCH_NEWER_COMMENTS_REQUEST } from '@atlassian/jira-issue-view-store/src/actions/comment-actions.tsx';
import { convertAdfStringToObject } from '@atlassian/jira-rich-content/src/common/adf-parsing-utils.tsx';
import { isJsmTimelineEnabled } from '../feature-flags.tsx';
import { normalizeItemArray } from './gira/util.tsx';
import { transformUser, transformGraphQlUser } from './user-transformer.tsx';

const LOG_LOCATION = 'issue.transform.comment';

const CREATED_ORDER_DESCENDING = '-created';

const isThreadedCommentsEnabled = () => {
	const [expConfig] = UNSAFE_noExposureExp('jira_threaded_comment_experiment');
	return expConfig.get('is_enabled', false);
};

/**
 * Transform comment from the REST endpoint as identifier instead of groupId is given
 *
 * This is required as, on intial load of an issue, a comment visibility will be provided with groupId which comes from Gira and
 * is stored in the state. However, after a comment is edited, the REST endpoint uses identifier instead of groupId and this
 * will instead be stored in the state. We address this issue by using identifier in the transformer after an edit has occured.
 */
export const transformCommentVisibility = ({
	type,
	value,
	identifier,
}: {
	type: string;
	value: string;
	identifier?: string;
}): CommentVisibility => {
	switch (type) {
		case COMMENT_VISIBILITY_TYPE_GROUP: {
			return { value, type, groupId: identifier };
		}
		case COMMENT_VISIBILITY_TYPE_ROLE: {
			return { value, type };
		}
		default: {
			const message = `transformCommentVisibility: unknown comment visibility type received '${type}'`;
			log.safeErrorWithoutCustomerData(LOG_LOCATION, message, new Error(message));
			return { value, type: COMMENT_VISIBILITY_TYPE_ROLE };
		}
	}
};

/**
 * Transforms comment data from the REST endpoint. Used when adding, editing, and deleting comments.
 *
 * See: https://developer.atlassian.com/cloud/jira/platform/rest/v3/api-group-issue-comments
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const transformComment = (comment: any) => ({
	id: comment.id,
	author: comment.author ? transformUser(comment.author) : null,
	updateAuthor: comment.updateAuthor ? transformUser(comment.updateAuthor) : null,
	bodyAdf: comment.body,
	bodyHtml: undefined,
	visibility: comment.visibility ? transformCommentVisibility(comment.visibility) : null,
	createdDate: transformToISOString(comment.created),
	updatedDate: comment.updated ? transformToISOString(comment.updated) : null,
	edited: !!comment.updated && comment.updated !== comment.created,
	isInternal: !comment.jsdPublic,
	outsideComment: comment.outsideComment ?? false,
	...(isJsmTimelineEnabled()
		? { eventOccurredAt: comment.eventTime, jsdIncidentActivityViewHidden: comment.hidden }
		: {}),
});

/**
 * Transforms JiraCommentVisibilities from the Gira Endpoint
 */
export const transformJiraCommentVisibility = (node: JiraCommentVisibility): CommentVisibility => {
	switch (node.__typename) {
		case JIRA_COMMENT_VISIBILITY_TYPE_GROUP: {
			return {
				type: COMMENT_VISIBILITY_TYPE_GROUP,
				groupId: node.group.groupId,
				value: node.group.name,
			};
		}

		case JIRA_COMMENT_VISIBILITY_TYPE_ROLE: {
			return {
				type: COMMENT_VISIBILITY_TYPE_ROLE,
				value: node.name,
			};
		}
		default: {
			const message = `transformJiraCommentVisibility: unknown comment visibility type received '${node.__typename}'`;
			log.safeErrorWithoutCustomerData(LOG_LOCATION, message, new Error(message));
			return {
				type: COMMENT_VISIBILITY_TYPE_ROLE,
				value: node.name,
			};
		}
	}
};

/**
 * Transforms comment data fetched from the Gira endpoint.
 */
export const transformGraphQlComment = ({
	id,
	author,
	updateAuthor,
	jiraCommentVisibility,
	created,
	updated,
	bodyAdf,
	renderedBody,
	jsdPublic,
	jsdAuthorCanSeeRequest,
	eventOccurredAt,
	parentId,
	replies,
	isDeleted,
	jsdIncidentActivityViewHidden, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: any) => ({
	id,
	author: author ? transformGraphQlUser(author) : null,
	updateAuthor: updateAuthor ? transformGraphQlUser(updateAuthor) : null,
	visibility: jiraCommentVisibility ? transformJiraCommentVisibility(jiraCommentVisibility) : null,
	createdDate: transformToISOString(created),
	updatedDate: updated ? transformToISOString(updated) : null,
	edited: !!updated && updated !== created,
	bodyAdf: convertAdfStringToObject(bodyAdf),
	bodyHtml: renderedBody,
	isInternal: !jsdPublic,
	jsdAuthorCanSeeRequest,
	...(isThreadedCommentsEnabled() && parentId ? { parentId } : {}),
	...(isThreadedCommentsEnabled() && replies ? { replies } : {}),
	...(isThreadedCommentsEnabled() && isDeleted ? { isDeleted } : {}),
	...(isJsmTimelineEnabled() ? { eventOccurredAt } : {}),
	...(isJsmTimelineEnabled() ? { jsdIncidentActivityViewHidden } : {}),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const transformComments = (responseData: any) => {
	const comments = responseData.data.issueComments.nodes || [];
	return {
		comments: comments.map((comment: Comment) => transformGraphQlComment(comment)),
		totalComments: responseData.data.issueComments.totalCount,
		...(isThreadedCommentsEnabled() && responseData.data.issueComments.rootCommentsCount != null
			? { rootCommentsCount: responseData.data.issueComments.rootCommentsCount }
			: {}),
	};
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const normalizeComment = (comment: any): NormalizedComment => {
	const authorId = comment.author ? comment.author.id : null;

	const updateAuthorId = comment.updateAuthor ? comment.updateAuthor.id : null;

	const commentJsmTimelineProps = isJsmTimelineEnabled()
		? {
				eventOccurredAt: comment.eventOccurredAt,
				jsdIncidentActivityViewHidden: comment.jsdIncidentActivityViewHidden,
			}
		: {};

	const getCommentVisibility = () => comment.visibility ?? COMMENT_VISIBILITY_PUBLIC;

	return {
		id: comment.id,
		authorId,
		updateAuthorId,
		bodyAdf: comment.bodyAdf,
		bodyHtml: comment.bodyHtml,
		createdDate: comment.createdDate,
		updatedDate: comment.updatedDate,
		edited: comment.edited,
		visibility: getCommentVisibility(),
		isInternal: comment.isInternal,
		jsdAuthorCanSeeRequest: comment.jsdAuthorCanSeeRequest,
		...(fg('jira_threaded_comments_ga') ? { cursor: comment.cursor } : {}),
		...(isThreadedCommentsEnabled() && comment.parentId ? { parentId: comment.parentId } : {}),
		...(isThreadedCommentsEnabled() && comment.isDeleted ? { isDeleted: comment.isDeleted } : {}),
		...(isThreadedCommentsEnabled() && comment.replies ? { replies: comment.replies } : {}),
		...commentJsmTimelineProps,
	};
};

export const collateUsers = (comments: RawComment[]) => {
	const users = comments
		.filter((comment: RawComment) => comment.updateAuthor || comment.author)
		.reduce((accumulator: Array<RawUser>, { author, updateAuthor }: RawComment) => {
			if (author) {
				accumulator.push(author);
			}

			if (updateAuthor) {
				accumulator.push(updateAuthor);
			}

			return accumulator;
		}, []);

	const normalizedUsers: NormalizedUsers = normalizeItemArray(users, 'id');
	return normalizedUsers;
};

export const normalizeComments = (
	rawComments: RawComments,
	loadedComments: number,
	commentsStartIndex: number,
	type?: string,
	commentsPageInfo?: CommentsPageInfo,
): NormalizedLoadedComments => {
	const { comments, rootCommentsCount } = rawComments;
	const currentLoadedComments =
		isThreadedCommentsEnabled() && rootCommentsCount != null ? rootCommentsCount : comments.length;

	const normalizedUsers: NormalizedUsers = collateUsers(comments);

	const newStartIndex = Math.max(
		type === FETCH_NEWER_COMMENTS_REQUEST
			? commentsStartIndex - currentLoadedComments
			: commentsStartIndex,
		0,
	);

	return {
		users: normalizedUsers,
		comments: normalizeItemArray(comments.map(normalizeComment), 'id'),
		loadedComments: loadedComments + currentLoadedComments,
		commentsStartIndex: newStartIndex,
		totalComments: rawComments.totalComments,
		...(fg('jira_threaded_comments_ga') ? { commentsPageInfo } : {}),
	};
};

export const normalizeGiraComments = (
	rawComments: RawComments,
	loadedComments: number,
	commentsStartIndex: number,
	type?: string,
	orderBy?: string,
): NormalizedLoadedComments => {
	const { comments, rootCommentsCount } = rawComments;
	const currentLoadedComments =
		isThreadedCommentsEnabled() && rootCommentsCount != null ? rootCommentsCount : comments.length;
	let newStartIndex;

	if (type === FETCH_NEWER_COMMENTS_REQUEST) {
		newStartIndex = Math.max(
			orderBy === CREATED_ORDER_DESCENDING
				? commentsStartIndex - currentLoadedComments
				: commentsStartIndex,
			0,
		);
	} else {
		newStartIndex = Math.max(
			orderBy === CREATED_ORDER_DESCENDING
				? commentsStartIndex
				: commentsStartIndex - currentLoadedComments,
			0,
		);
	}

	const normalizedUsers: NormalizedUsers = collateUsers(comments);

	return {
		users: normalizedUsers,
		comments: normalizeItemArray(comments.map(normalizeComment), 'id'),
		loadedComments: loadedComments + currentLoadedComments,
		commentsStartIndex: newStartIndex,
		totalComments: rawComments.totalComments,
	};
};
