/** @jsx jsx */
import type { ReactNode } from 'react';
import { styled as compileStyled, css, jsx } from '@compiled/react';
// eslint-disable-next-line jira/restricted/styled-components-migration, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled, { css as styledCss } from 'styled-components';
import { token } from '@atlaskit/tokens';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';

export type InlineEditContainerProps = {
	hasValue?: boolean;
	isEditable?: boolean;
};

/*
 Using calc as this margin negates 2 things:
 - Left displacement which ensures there's more space for the input element that becomes visible on focus.
 - The focus outline offset for the badge (implemented as a 2px border) which is in PX everywhere currently.
 The REM is affected by browser font size settings while PX isn't. So, this ensures the spacing behaves in a consistent manner.
 */
const MARGIN_LEFT = `calc(${token('space.negative.075', '-6px')} - 2px)`;

const inlineEditContainerStyles = css({
	marginLeft: token('space.negative.050', '-4px'),
	marginTop: token('space.negative.100', '-8px'),
	paddingRight: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'& div[data-read-view-fit-container-width]': {
		width: '100%',
	},
});

const inlineEditContainerPaddingStylesWithValue = css({ padding: '0px' });

const inlineEditContainerPaddingStylesWithoutValue = css({
	padding: `${token('space.100', '8px')} 0`,
});

const InlineEditContainerNew = ({
	children,
	hasValue,
	...props
}: {
	children: ReactNode;
	hasValue?: boolean;
}) => (
	<div
		css={[
			inlineEditContainerStyles,
			hasValue && inlineEditContainerPaddingStylesWithValue,
			!hasValue && inlineEditContainerPaddingStylesWithoutValue,
		]}
		{...props}
	>
		{children}
	</div>
);

// TODO: migrate to object syntax. Autofix is available for many cases. Remove the eslint-disable for @atlaskit/design-system/no-styled-tagged-template-expression to check.
// eslint-disable-next-line  @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const InlineEditContainerOld = styled.div<InlineEditContainerProps>`
	margin-left: ${MARGIN_LEFT};
	margin-top: ${token('space.negative.100', '-8px')};
	padding-right: ${token('space.100', '8px')};

	& div[data-read-view-fit-container-width] {
		width: 100%;
		min-height: ${gridSize * 4}px;
		${({ hasValue }) =>
			hasValue
				? // eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression
					styledCss`
						padding: 0;
					`
				: // eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression
					styledCss`
						padding: ${token('space.100', '8px')} 0;
					`}
	}
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles
export const InlineEditContainer = componentWithFG(
	'issue_view_field_config_edit',
	InlineEditContainerNew,
	InlineEditContainerOld,
);

// InlineEditContainer is being consumed in a lot of different areas,
// many of which have not been migrated to @compiled/react.
// Using this without styledComponentWithCondition for the time being until these are all converted.
// Tracking this here https://jplat.jira.atlassian.cloud/browse/JIV-15844
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const InlineEditContainerExperiment = compileStyled.div<InlineEditContainerProps>({
	marginLeft: token('space.negative.100', '-8px'),
	marginTop: token('space.negative.100', '-8px'),
	paddingRight: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& div[data-read-view-fit-container-width]': {
		width: '100%',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		minHeight: `${gridSize * 4}px`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		padding: ({ hasValue }) => (hasValue ? 0 : '7px 0'),
	},
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any,   @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ReadViewContainer = styled.div<any>({
	wordBreak: 'break-word',
	position: 'relative',
	left: token('space.075', '6px'),
});

const readViewContainerSelectorName = 'jira-issue-field-inline-edit-read-view-container';
export const readOnlyViewContainerSelectorName =
	'jira-issue-field-inline-edit-readonly-view-container';
export const READ_VIEW_CONTAINER_SELECTOR = `[data-component-selector="${readViewContainerSelectorName}"]`;
export const READONLY_VIEW_CONTAINER_SELECTOR = `[data-component-selector="${readOnlyViewContainerSelectorName}"]`;

ReadViewContainer.defaultProps = {
	'data-component-selector': readViewContainerSelectorName,
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ReadViewContainerCompiled = compileStyled.div({
	wordBreak: 'break-word',
	position: 'relative',
	left: token('space.075', '6px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const EditViewContainerCompiled = compileStyled.div({
	position: 'relative',
});
