import React, { Component } from 'react';
import noop from 'lodash/noop';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { ff } from '@atlassian/jira-feature-flagging-using-meta';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	type default as FetchError,
	ValidationError,
} from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { withFlagService } from '@atlassian/jira-flags';
import { getUpdateAnalyticsFlowHelper } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/index.tsx';
import {
	FieldValueSubscriber,
	type FieldValueServiceActions,
} from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import { useIsEditFromIssueView } from '@atlassian/jira-issue-hooks/src/services/use-is-edit-from-issue-view/index.tsx';
import type { TriggerIssueTransitionModalActions } from '@atlassian/jira-issue-transition-trigger/src/common/types.tsx';
import { useOpenIssueTransitionModal } from '@atlassian/jira-issue-transition-trigger/src/utils/use-trigger-issue-transition-modal/index.tsx';
import { useShowFlag } from '@atlassian/jira-issue-transition-use-show-flag/src/ui/use-show-flag/index.tsx';
import { sendExperienceAnalytics } from '@atlassian/jira-issue-view-analytics/src/controllers/send-experience-analytics/index.tsx';
import isValidExperienceError from '@atlassian/jira-issue-view-common-utils/src/utils/is-valid-experience-error.tsx';
import { useWorkflowTransitionsActions } from '@atlassian/jira-issue-workflow-transitions-services/src/main.tsx';
import type { WorkflowTransitionsActions } from '@atlassian/jira-issue-workflow-transitions-services/src/types.tsx';
import { createAri } from '@atlassian/jira-platform-ari/src/index.tsx';
import { STATUS_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import {
	fireTrackAnalytics,
	fireOperationalAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { STATUS_FIELD_POST_TRANSITION_KEY } from '../../common/constants.tsx';
import type { StatusValue, StatusTransition } from '../../common/types.tsx';
import { ISSUE_TRANSITION_STATUS_FIELD } from '../../constants.tsx';
import { transitionIssueExperienceDescription } from '../experience-descriptions/index.tsx';
import statusTransitionErrorFlag from '../flags/transition-failed-error/main.tsx';
import statusTransitionValidationErrorFlag from '../flags/validation-error/main.tsx';
import type { Props, State, API } from './types.tsx';

export const DIALOG_SUBMIT_BTN = '#issue-workflow-transition-submit';

export const safeUnbindDomEvent = (selector: string, event: string, handler: () => void) => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const el = document.querySelector(selector);
	if (el) {
		el.removeEventListener(event, handler);
	}
};

export const safeBindDomEvent = (selector: string, event: string, handler: () => void) => {
	safeUnbindDomEvent(selector, event, handler);

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const el = document.querySelector(selector);
	if (el) {
		el.addEventListener(event, handler);
	}
};

// eslint-disable-next-line jira/react/no-class-components
class StatusService extends Component<Props, State> {
	static defaultProps = {
		onSubmit: noop,
		onSuccess: noop,
		onFailure: noop,
		onEditStart: noop,
		onEditCancel: noop,
	};

	/**
	 * Lets the consumer control the value of this component. If the consumer passes a `value` prop
	 * it will override the value in state, as long as the component is not in loading state. That's because the component
	 * will only let the consumer know about the new status value once the transition is complete (i.e. loading=false).
	 *
	 * This lifecycle method gets called before every invocation of render().
	 */
	static getDerivedStateFromProps(props: Props, state: State): Partial<State> | null {
		if (state.loading || !props.value) {
			return null;
		}

		return {
			value: props.value,
		};
	}

	constructor(props: Props) {
		super(props);

		const value = props.value || props.initialValue;
		if (value == null) {
			throw new Error('Either value or initialValue prop is required.');
		}

		this.state = {
			value,
			lastSavedValue: value,
			loading: false,
			error: null,
		};
	}

	componentDidMount() {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.addEventListener('legacyJiraDialogContentReady', this.onDialogContentReady);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.addEventListener('legacyJiraDialogBeforeHide', this.handleBeforeDialogHide);
	}

	componentDidUpdate = (prevProps: Props) => {
		// If we receive a new prop that is different to the old one and it is different
		// to our state then trust it and update the state.
		if (
			this.props.initialValue &&
			prevProps.initialValue &&
			prevProps.initialValue.id !== this.props.initialValue.id &&
			this.props.initialValue.id !== this.state.value.id
		) {
			this.setState({ loading: false, value: this.props.initialValue });
		}
	};

	componentWillUnmount() {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.removeEventListener('legacyJiraDialogContentReady', this.onDialogContentReady);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.removeEventListener('legacyJiraDialogBeforeHide', this.handleBeforeDialogHide);

		this.unbindDialogFormHandlers();
		this.isDialogOpened = false;
	}

	isDialogOpened = false;

	isDialogConfirmPressed = false;

	onDialogContentReady = () => {
		// This event fired by a dialog before the dialog itself is rendered and visible to the user
		// That's why we need to postpone sending the response to the next moment after it became visible
		setTimeout(() => {
			this.bindDialogFormHandlers();
			this.isDialogOpened = true;
			this.isDialogConfirmPressed = false;
		});
	};

	bindDialogFormHandlers = () => {
		safeBindDomEvent(DIALOG_SUBMIT_BTN, 'click', this.onClickDialogConfirm);
	};

	unbindDialogFormHandlers = () => {
		safeUnbindDomEvent(DIALOG_SUBMIT_BTN, 'click', this.onClickDialogConfirm);
	};

	handleBeforeDialogHide = () => {
		this.unbindDialogFormHandlers();
		this.isDialogOpened = false;
		this.isDialogConfirmPressed = false;
	};

	onClickDialogConfirm = () => {
		// Dialog confirm button press will result in either successfull closing of dialog, or error and dialog re-rendering,
		// so we are starting both interactions
		this.isDialogConfirmPressed = true;
	};

	resetError = () =>
		this.setState({
			error: null,
		});

	submit = (transition: StatusTransition, event: UIAnalyticsEvent) => {
		const oldValue = this.state.value;
		const newValue = transition.to;
		const isEditFromIssueView = fg('one_event_rules_them_all_fg') && useIsEditFromIssueView(event);
		if (fg('one_event_rules_them_all_fg')) {
			getUpdateAnalyticsFlowHelper().fireAnalyticsEnd(STATUS_TYPE, {
				analytics: event,
				attributes: {
					fieldType: STATUS_TYPE,
					oldValId: isEditFromIssueView ? oldValue.statusId : oldValue.id,
					newValId: newValue.id,
					oldStatusCategoryId: oldValue.statusCategory.id,
					newStatusCategoryId: newValue.statusCategory.id,
					isInlineEditing: !isEditFromIssueView,
				},
			});
		}
		this.setState({ loading: true, value: newValue });
		this.props.onSubmit(oldValue, newValue, { transition }, event);
	};

	success = ({
		status,
		event,
		viaDialog = false,
	}: {
		status: StatusValue;
		viaDialog?: boolean;
		event: UIAnalyticsEvent;
	}) => {
		this.setState({ loading: false, lastSavedValue: status });
		this.props.fieldValueActions.setFieldValue(this.props.issueKey, STATUS_TYPE, status);
		this.props.onSuccess(status, viaDialog, event);

		fireTrackAnalytics(event, 'statusField transitioned', 'statusField');

		sendExperienceAnalytics(
			transitionIssueExperienceDescription(
				true,
				'POST',
				'Issue View Status Service',
				this.props.projectType,
			),
		);
	};

	failure = (error: FetchError, event: UIAnalyticsEvent) => {
		this.setState({
			error,
			loading: false,
			value: this.state.lastSavedValue || this.props.value || this.props.initialValue,
		});

		this.props.onFailure(error);

		const isValidationError = error instanceof ValidationError;
		if (isValidationError) {
			this.props.flagService.showFlag(statusTransitionValidationErrorFlag(error.message));
		} else {
			this.props.flagService.showFlag(statusTransitionErrorFlag());
		}

		fireOperationalAnalytics(event, 'statusField postTransitionFailed', {
			validationError: isValidationError,
			isClientFetchError: isClientFetchError(error),
		});

		if (isValidExperienceError(error)) {
			sendExperienceAnalytics(
				transitionIssueExperienceDescription(
					false,
					'POST',
					'Issue View Status Service',
					this.props.projectType,
					error.message,
					error.traceId,
					fg('thor_populate_missing_attributes_across_issue_view') ? error.statusCode : undefined,
				),
			);
		} else {
			sendExperienceAnalytics(
				transitionIssueExperienceDescription(
					true,
					'POST',
					'Issue View Status Service',
					this.props.projectType,
				),
			);
		}
	};

	cancel = () => {
		// @ts-expect-error - TS2322 - Type 'Status | undefined' is not assignable to type 'Status'.
		this.setState({ loading: false, value: this.props.value || this.props.initialValue });
		this.props.onEditCancel();
		this.props.workflowTransitionsActions.setTransitionInProgress(false);
	};

	transitionStatus = async (transition: StatusTransition, event: UIAnalyticsEvent) => {
		const { appearance, issueKey, renderIssueTransitionModal, renderIssueTransitionSuccessFlag } =
			this.props;
		const newStatus = transition.to;

		this.submit(transition, event);

		try {
			if (transition.hasScreen) {
				const handleDialogSuccess = () => {
					fireTrackAnalytics(event, 'issueTransition success', {
						triggerPointKey: ISSUE_TRANSITION_STATUS_FIELD,
						isModalOpen: true,
					});

					fg('show-modernised-issue-transition-success-flag') &&
						renderIssueTransitionSuccessFlag?.(issueKey, newStatus.name);

					return this.success({ status: newStatus, event, viaDialog: true });
				};

				const handleDialogCancel = () => {
					fireTrackAnalytics(event, 'issueTransition cancel', {
						triggerPointKey: ISSUE_TRANSITION_STATUS_FIELD,
						isModalOpen: true,
					});
					return this.cancel();
				};

				const handleDialogError = () => {
					fireTrackAnalytics(event, 'issueTransition failed', {
						triggerPointKey: ISSUE_TRANSITION_STATUS_FIELD,
						isModalOpen: true,
					});
				};
				if (renderIssueTransitionModal) {
					const { issueId } = this.props;
					renderIssueTransitionModal({
						payload: {
							issueId,
							issueKey,
							transitionId: transition.id,
						},
						triggerPointKey:
							appearance === 'lozenge'
								? 'issue-transition-status-field-trigger'
								: 'issue-transition-issue-view',
						onDialogSuccess: handleDialogSuccess,
						onDialogCancel: handleDialogCancel,
						onDialogError: handleDialogError,
						analyticsEvent: event,
					});
				}
			} else {
				if (ff('relay-migration-issue-fields-status_qg82c') && this.props.onUpdateStatusMutation) {
					await this.props.onUpdateStatusMutation?.({
						transitionId: transition.id,
						newStatus,
					});
				} else {
					await performPostRequest(`/rest/api/2/issue/${issueKey}/transitions`, {
						body: JSON.stringify({ transition }),
					});
				}
				fireTrackAnalytics(event, 'issueTransition success', {
					triggerPointKey: ISSUE_TRANSITION_STATUS_FIELD,
					isModalOpen: false,
				});

				fg('show-modernised-issue-transition-success-flag') &&
					renderIssueTransitionSuccessFlag?.(issueKey, newStatus.name);

				this.success({ status: newStatus, event });
			}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			log.safeErrorWithoutCustomerData(STATUS_FIELD_POST_TRANSITION_KEY, error);
			fireTrackAnalytics(event, 'issueTransition failed', {
				triggerPointKey: ISSUE_TRANSITION_STATUS_FIELD,
				isModalOpen: false,
			});
			this.failure(error, event);
		}
	};

	api: API = {
		transitionStatus: this.transitionStatus,
		resetError: this.resetError,
	};

	render() {
		return this.props.children({ ...this.state, ...this.api });
	}
}

const StatusServiceForMigrateIssueTransitionEnabled = (props: Props) => (
	<StatusServiceWithoutAriId {...props} />
);
const StatusServiceWithoutAriIdOld = (props: Props) => {
	const { issueId } = props;
	const cloudId = useCloudId();
	const ariIssueId = issueId
		? createAri({
				resourceOwner: 'jira',
				cloudId,
				resourceType: 'issue',
				resourceId: issueId,
			})
		: undefined;

	const renderIssueTransitionModal = useOpenIssueTransitionModal();

	return (
		<StatusService
			{...props}
			renderIssueTransitionModal={renderIssueTransitionModal}
			issueId={ariIssueId}
		/>
	);
};

const StatusServiceWithoutAriIdNew = (props: Props) => {
	const { issueId } = props;
	const cloudId = useCloudId();
	const ariIssueId = issueId
		? createAri({
				resourceOwner: 'jira',
				cloudId,
				resourceType: 'issue',
				resourceId: issueId,
			})
		: undefined;

	const renderIssueTransitionModal = useOpenIssueTransitionModal();
	const { showIssueTransitionSuccessFlag } = useShowFlag();

	return (
		<StatusService
			{...props}
			renderIssueTransitionModal={renderIssueTransitionModal}
			renderIssueTransitionSuccessFlag={showIssueTransitionSuccessFlag}
			issueId={ariIssueId}
		/>
	);
};

const StatusServiceWithoutAriId = componentWithFG(
	'show-modernised-issue-transition-success-flag',
	StatusServiceWithoutAriIdNew,
	StatusServiceWithoutAriIdOld,
);

const StatusWithValueService = (
	props: Flow.Diff<
		Props,
		{
			fieldValueActions: FieldValueServiceActions;
			workflowTransitionsActions: WorkflowTransitionsActions;
			renderIssueTransitionSuccessFlag?: (issueKey: string, status: string) => void;
			renderIssueTransitionModal?: TriggerIssueTransitionModalActions['openIssueTransitionModal'];
		}
	>,
) => {
	const [, workflowTransitionsActions] = useWorkflowTransitionsActions();
	return (
		<FieldValueSubscriber issueKey={props.issueKey} fieldKey={STATUS_TYPE}>
			{(value, fieldValueActions) => {
				const statusServiceProps = {
					// Overrides the value in the store if the consumer really wants to do it
					value,
					...props,
					fieldValueActions,
					workflowTransitionsActions,
				};

				return <StatusServiceForMigrateIssueTransitionEnabled {...statusServiceProps} />;
			}}
		</FieldValueSubscriber>
	);
};
const wrappedStatusService = withFlagService(StatusWithValueService);

export { wrappedStatusService as StatusService };
export { StatusService as BaseStatusService };
