import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import {
	ASSIGNEE_TYPE,
	PRIORITY_TYPE,
	NUMBER_CF_TYPE,
	SPRINT_TYPE,
	TEXT_GQL_FIELD,
	PARENT_TYPE,
	DATE_CF_TYPE,
	STATUS_TYPE,
	DATETIME_CF_TYPE,
	PROJECT_PICKER_CF_TYPE,
	URL_CF_TYPE,
	STORY_POINTS_TYPE,
	REPORTER_TYPE,
	STORY_POINT_ESTIMATE_CF_TYPE,
	SECURITY_LEVEL_TYPE,
	SECURITY_LEVEL_TYPE_OVERRIDE,
	SUMMARY_TYPE,
	MESSAGE_VIEW_CF_TYPE,
	MESSAGE_EDIT_CF_TYPE,
	USER_CF_TYPE,
	TIME_TRACKING_TYPE,
	MULTI_CHECKBOXES_CF_TYPE,
	PEOPLE_CF_TYPE,
	COLOR_CF_TYPE,
	VOTES_TYPE,
	EPIC_COLOR_TYPE,
	COMPONENTS_TYPE,
	APPROVERS_LIST_CF_TYPE,
	TIME_ESTIMATE_TYPE,
	TEAMS_PLATFORM_CF_TYPE,
	WATCHES_TYPE,
	MULTI_USER_CF_TYPE,
	PARTICIPANTS_CF_TYPE,
	ISSUE_TYPE,
	RESOLUTION_TYPE,
	GROUP_CF_TYPE,
	MULTI_GROUP_CF_TYPE,
	RADIO_BUTTONS_CF_TYPE,
	REQUEST_PARTICIPANTS_CF_TYPE,
	TEXT_AREA_CF_TYPE,
	ENVIRONMENT_TYPE,
	DESCRIPTION_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';

export const getSoftRefreshSupportedFields = (): string[] => {
	const fields: string[] = [STATUS_TYPE];
	if (fg('relay-migration-issue-fields-assignee-fg')) {
		fields.push(ASSIGNEE_TYPE);
	}
	if (fg('relay-migration-issue-fields-reporter')) {
		fields.push(REPORTER_TYPE);
	}
	if (fg('relay-migration-issue-fields-user-fg')) {
		fields.push(USER_CF_TYPE);
	}
	if (fg('relay-migration-issue-fields-priority')) {
		fields.push(PRIORITY_TYPE);
	}

	if (fg('relay-migration-issue-fields-number')) {
		fields.push(NUMBER_CF_TYPE, STORY_POINT_ESTIMATE_CF_TYPE, STORY_POINTS_TYPE);
	}

	if (ff('relay-migration-issue-fields-sprint_jozpu')) {
		fields.push(SPRINT_TYPE);
	}

	if (fg('relay-migration-issue-fields-single-line-text-fg')) {
		fields.push(TEXT_GQL_FIELD);
	}

	if (fg('relay-migration-issue-header-and-parent')) {
		fields.push(PARENT_TYPE);
	}

	if (fg('relay-migration-issue-fields-date-sg')) {
		fields.push(DATE_CF_TYPE);
	}

	if (fg('relay-migration-issue-fields-project-select')) {
		fields.push(PROJECT_PICKER_CF_TYPE);
	}

	if (fg('relay-migration-issue-fields-date-time-fg')) {
		fields.push(DATETIME_CF_TYPE);
	}

	if (fg('relay-migration-issue-fields-url')) {
		fields.push(URL_CF_TYPE);
	}

	if (fg('relay-migration-issue-header-and-parent')) {
		fields.push(SECURITY_LEVEL_TYPE, SECURITY_LEVEL_TYPE_OVERRIDE);
	}

	if (ff('relay-migration-issue-fields-summary_eog1v')) {
		fields.push(SUMMARY_TYPE);
	}

	if (fg('relay-migration-issue-fields-single-line-text-msg')) {
		fields.push(MESSAGE_VIEW_CF_TYPE, MESSAGE_EDIT_CF_TYPE);
	}

	if (ff('relay-migration-issue-fields-time-tracking_eefh0')) {
		fields.push(TIME_TRACKING_TYPE);
	}

	if (fg('relay-migration-issue-fields-checkbox-select')) {
		fields.push(MULTI_CHECKBOXES_CF_TYPE);
	}

	if (fg('relay-migration-issue-fields-people-fg')) {
		fields.push(PEOPLE_CF_TYPE);
	}

	if (fg('relay-migration-issue-fields-approvers-fg')) {
		fields.push(APPROVERS_LIST_CF_TYPE);
	}

	if (fg('relay-migration-issue-fields-color')) {
		fields.push(COLOR_CF_TYPE, EPIC_COLOR_TYPE);
	}

	if (ff('relay-migration-issue-fields-components-select_prpz1')) {
		fields.push(COMPONENTS_TYPE);
	}

	if (fg('relay-migration-issue-fields-original-estimate')) {
		fields.push(TIME_ESTIMATE_TYPE);
	}

	if (fg('relay-migration-issue-fields-team-field-next')) {
		fields.push(TEAMS_PLATFORM_CF_TYPE);
	}

	if (fg('relay-migration-issue-fields-multi-user-fg')) {
		fields.push(MULTI_USER_CF_TYPE);
	}

	if (ff('relay-migration-issue-fields-participants')) {
		fields.push(PARTICIPANTS_CF_TYPE);
	}

	if (ff('relay-migration-issue-fields-request-participants_itocm')) {
		fields.push(REQUEST_PARTICIPANTS_CF_TYPE);
	}

	if (fg('relay-migration-issue-header-and-parent')) {
		fields.push(ISSUE_TYPE);
	}

	if (fg('relay-migration-issue-header-and-parent')) {
		fields.push(WATCHES_TYPE);
	}

	if (fg('relay-migration-issue-fields-resolution')) {
		fields.push(RESOLUTION_TYPE);
	}

	if (fg('relay-migration-issue-fields-multi-group-picker-fg')) {
		fields.push(MULTI_GROUP_CF_TYPE);
	}

	if (fg('relay-migration-issue-header-and-parent')) {
		fields.push(VOTES_TYPE);
	}

	if (fg('relay-migration-issue-fields-group-picker-sg')) {
		fields.push(GROUP_CF_TYPE);
	}

	if (ff('relay-migration-issue-fields-status_qg82c')) {
		fields.push(STATUS_TYPE);
	}

	if (fg('relay-migration-issue-fields-radio')) {
		fields.push(RADIO_BUTTONS_CF_TYPE);
	}

	if (fg('relay-migration-issue-fields-multi-line-text-fg')) {
		fields.push(TEXT_AREA_CF_TYPE, ENVIRONMENT_TYPE, DESCRIPTION_TYPE);
	}

	return fields;
};
