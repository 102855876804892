import React from 'react';

import { Inline, Text, xcss } from '@atlaskit/primitives';
import Toggle from '@atlaskit/toggle';

type SettingItemProps = {
	icon: JSX.Element;
	title: string;
	description: string;
	defaultValue: boolean;
	toggle: () => void;
};

const textContainerStyle = xcss({
	display: 'block',
	paddingLeft: 'space.150',
});
const titleStyle = xcss({
	fontWeight: 'bold',
	paddingBottom: 'space.025',
});

export const SettingItem = (props: SettingItemProps) => {
	const { icon, title, description, toggle, defaultValue } = props;

	return (
		<Inline spread="space-between" alignBlock="center">
			<Inline>
				<Inline>{icon}</Inline>
				<Inline xcss={textContainerStyle}>
					<Inline xcss={titleStyle}>{title}</Inline>
					<Text color="color.text.subtle">{description}</Text>
				</Inline>
			</Inline>
			<Toggle id="toggle-default" onChange={toggle} defaultChecked={defaultValue} />
		</Inline>
	);
};
