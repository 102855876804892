import { defineMessages } from 'react-intl-next';

export default defineMessages({
	betaLabel: {
		id: 'fabric.editor.ai.screens.preview.betaLabel',
		defaultMessage: 'Beta',
		description: 'The label describing the beta status of the Atlassian Intelligence feature',
	},
	infoIconLabel: {
		id: 'fabric.editor.ai.commandPalette.footer.infoIconLabel',
		defaultMessage: 'Information',
		description: 'The label for the information icon',
	},
	atlassianIntelligenceCopyrightShort: {
		id: 'fabric.editor.ai.commandPalette.footer.atlassianIntelligenceCopyrightShort',
		defaultMessage: 'Powered by AI',
		description:
			'Text to display in the right part of Editor AI command palette footer in short form for AI requests',
	},
	atlassianIntelligenceCopyright: {
		id: 'fabric.editor.ai.commandPalette.footer.atlassianIntelligenceCopyright',
		defaultMessage: 'Powered by Atlassian Intelligence',
		description:
			'Text to display in the right part of Editor AI command palette footer for AI requests',
	},
	rovoCopyright: {
		id: 'fabric.editor.ai.commandPalette.footer.rovoCopyright',
		defaultMessage: 'Rovo',
		description:
			'Text to display in the right part of Editor AI command palette footer for Rovo requests',
	},
	disclaimerTextShort: {
		id: 'fabric.editor.ai.commandPalette.footer.disclaimerTextShort',
		defaultMessage: 'Content quality may vary',
		description:
			'Text to display in the disclaimer part of Editor AI command palette footer in short form',
	},
	disclaimerText: {
		id: 'fabric.editor.ai.commandPalette.footer.disclaimerText',
		defaultMessage: 'Content is based on your permissions, and its quality may vary',
		description: 'Text to display in the disclaimer part of Editor AI command palette footer',
	},
});
