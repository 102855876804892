import { useCallback, useState } from 'react';
import noop from 'lodash/noop';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
// eslint-disable-next-line jira/wrm/no-load-bridge
import { jiraBridge } from '@atlassian/jira-common-bridge';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { ValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { getUpdateAnalyticsFlowHelper } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/index.tsx';
import { useIssueId, useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useIsEditFromIssueView } from '@atlassian/jira-issue-hooks/src/services/use-is-edit-from-issue-view/index.tsx';
import { Reason } from '@atlassian/jira-issue-refresh-service/src/types.tsx';
import { useIssueViewFieldUpdateEvents } from '@atlassian/jira-issue-view-field-update-events/src/services/issue-view-field-update-events/index.tsx';
import { useIsMounted } from '@atlassian/jira-platform-react-hooks-use-is-mounted/src/common/utils/index.tsx';
import {
	fireTrackAnalytics,
	fireOperationalAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { FieldOptions, IdOnlyResponse } from '../../common/types.tsx';
import { useFieldValue } from '../field-value-service/index.tsx';
import type { SaveFieldHook } from './types.tsx';
import {
	defaultSaveField,
	defaultAddValuesToField,
	defaultRemoveValuesFromField,
	defaultSaveFieldById,
} from './utils.tsx';

/**
 * Some responses contain `fields` property without `fieldKey`.
 * We must avoid changing the state of this fields by checking `fieldKey` property in the `fields`.
 * @param response
 * @param fieldKey
 */
const getFieldValue = <ReturnType, Response extends IdOnlyResponse<ReturnType>>(
	response: Response,
	fieldKey: string,
) => {
	if (!response) {
		return null;
	}

	if (response.fields) {
		if (Object.hasOwnProperty.call(response.fields, fieldKey)) {
			return response.fields[fieldKey];
		}
		return null;
	}
	return response;
};

const shouldCallSetFieldValue = <ReturnType, Response extends IdOnlyResponse<ReturnType>>(
	response: Response,
	fieldKey: string,
) => {
	if (!response) {
		return false;
	}
	if (response.fields) {
		return !!Object.hasOwnProperty.call(response.fields, fieldKey);
	}
	return true;
};

export const useEditFieldOriginal = <InputShape, Meta, ReturnType, IdOnlyInputShape>(
	fieldOptions: FieldOptions<InputShape, Meta, ReturnType, IdOnlyInputShape>,
): SaveFieldHook<InputShape, Meta, ReturnType, IdOnlyInputShape> => {
	// di-ignore - Please fix parent-switcher examples
	const baseUrl = '';
	const {
		issueKey,
		issueId,
		fieldKey,
		fieldType,
		initialValue,
		analyticsFieldKeyAlias,
		analyticsFieldTypeAlias,
		mapNonOptimisticResponse = getFieldValue,
		saveField = defaultSaveField,
		saveFieldById = defaultSaveFieldById,
		addValuesToField = defaultAddValuesToField,
		removeValuesFromField = defaultRemoveValuesFromField,
		onSubmit = noop,
		onSuccess = noop,
		onFailure = noop,
	} = fieldOptions;

	// We need to track the mounted state as it is possible that a component
	// using this hook can be unmounted before the async edit operation is completed.
	// Causing a react warning about setting state on an unmounted component.
	const isMounted = useIsMounted();
	const isEditFromIssueView = useIsEditFromIssueView;

	const [fieldPersistedValue, { setFieldValue }] = useFieldValue({
		issueKey,
		fieldKey,
	});

	const fieldValue = fieldPersistedValue !== undefined ? fieldPersistedValue : initialValue;

	const [error, setError] = useState<Error | null>(null);
	const [loading, setLoading] = useState(false);
	const resetError = useCallback(() => {
		if (isMounted.current) {
			setError(null);
		}
	}, [setError, isMounted]);

	const editFieldValue = useCallback(
		async <Shape, Result extends IdOnlyResponse<ReturnType>>(
			editOperation: (
				issueKey: IssueKey,
				fieldKey: string,
				valuesForOperation: Shape,
				baseUrl: string,
				meta?: Meta,
			) => Promise<Result>,
			editOperationType: string,
			valuesForOperation: Shape,
			valuesForUI: Shape,
			meta: Meta,
			analyticsEvent: NonNullable<UIAnalyticsEvent>,
			options: { isOptimistic: boolean } = { isOptimistic: true },
		) => {
			let result;
			const actualFieldType = analyticsFieldTypeAlias ?? fieldType;
			const analyticsData = {
				editOperationType,
				issueId,
				fieldKey: analyticsFieldKeyAlias ?? fieldKey,
				fieldType: actualFieldType,
			};

			if (isMounted.current) {
				setLoading(true);
				setError(null);
			}

			try {
				onSubmit(fieldValue, valuesForOperation, meta, analyticsEvent);

				if (options.isOptimistic) {
					setFieldValue(issueKey, fieldKey, valuesForUI);
				}

				result = await editOperation(issueKey, fieldKey, valuesForOperation, baseUrl, meta);

				const fieldValueResponse = mapNonOptimisticResponse(result, fieldKey);
				shouldCallSetFieldValue<ReturnType, IdOnlyResponse<ReturnType>>(result, fieldKey) &&
					setFieldValue(issueKey, fieldKey, fieldValueResponse);
				onSuccess(fieldValueResponse ?? valuesForOperation, analyticsEvent, meta);

				fireTrackAnalytics(analyticsEvent, 'field updated', analyticsData);
				fireOperationalAnalytics(analyticsEvent, 'field updateSucceeded', analyticsData);

				if (fg('one_event_rules_them_all_fg')) {
					getUpdateAnalyticsFlowHelper().fireAnalyticsEnd(fieldKey, {
						analytics: analyticsEvent,
						attributes: {
							fieldType: actualFieldType,
							isInlineEditing: !isEditFromIssueView(analyticsEvent),
						},
					});
				}

				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (ex: any) {
				result = Promise.reject(ex);
				onFailure(ex, valuesForOperation);
				setFieldValue(issueKey, fieldKey, fieldValue);

				if (isMounted.current) {
					setError(ex);
				}

				const isValidationError = ex instanceof ValidationError;
				if (!isValidationError) {
					log.safeErrorWithoutCustomerData(
						'issue-field.save-value',
						`Failed to update field ${fieldKey} of type ${fieldType}`,
						ex,
					);
				}

				fireOperationalAnalytics(analyticsEvent, 'field updateFailed', {
					fieldKey,
					fieldType: analyticsFieldTypeAlias ?? fieldType,
					isValidationError,
					isClientFetchError: isClientFetchError(ex),
				});

				if (fg('one_event_rules_them_all_fg')) {
					getUpdateAnalyticsFlowHelper().endSession(fieldKey);
				}
			}

			if (isMounted.current) {
				setLoading(false);
			}

			return result;
		},
		[
			issueId,
			analyticsFieldKeyAlias,
			fieldKey,
			analyticsFieldTypeAlias,
			fieldType,
			isMounted,
			onSubmit,
			fieldValue,
			setFieldValue,
			issueKey,
			onSuccess,
			onFailure,
			mapNonOptimisticResponse,
			isEditFromIssueView,
		],
	);

	const saveValue = useCallback(
		async (newValue: InputShape, meta: Meta, analyticsEvent: NonNullable<UIAnalyticsEvent>) =>
			editFieldValue(saveField, 'set', newValue, newValue, meta, analyticsEvent),
		[editFieldValue, saveField],
	);

	const saveById = useCallback(
		async (newValue: IdOnlyInputShape, meta: Meta, analyticsEvent: NonNullable<UIAnalyticsEvent>) =>
			editFieldValue(saveFieldById, 'set', newValue, newValue, meta, analyticsEvent, {
				isOptimistic: false,
			}),
		[editFieldValue, saveFieldById],
	);

	const addValues = useCallback(
		async (
			valuesToAdd: InputShape,
			expectedValuesAfterAdding: InputShape,
			meta: Meta,
			analyticsEvent: NonNullable<UIAnalyticsEvent>,
		) =>
			editFieldValue(
				addValuesToField,
				'add',
				valuesToAdd,
				expectedValuesAfterAdding,
				meta,
				analyticsEvent,
			),
		[editFieldValue, addValuesToField],
	);

	const removeValues = useCallback(
		async (
			valuesToRemove: InputShape,
			expectedValuesAfterRemoval: InputShape,
			meta: Meta,
			analyticsEvent: NonNullable<UIAnalyticsEvent>,
		) =>
			editFieldValue(
				removeValuesFromField,
				'remove',
				valuesToRemove,
				expectedValuesAfterRemoval,
				meta,
				analyticsEvent,
			),
		[editFieldValue, removeValuesFromField],
	);

	return [
		{
			value: fieldValue,
			loading,
			error,
		},
		{
			saveById,
			saveValue,
			addValues,
			removeValues,
			resetError,
		},
	];
};

export const useContextualIssueIdForIssueKey = (issueKey: string) => {
	const contextualIssueId = useIssueId();
	const contextualIssueKey = useIssueKey();
	return contextualIssueKey === issueKey ? contextualIssueId : null;
};

const getAdditionalProperties = <T,>(meta: T, isEditFromIssueView: boolean) => {
	if (!meta || typeof meta === 'object') {
		return { ...(meta ?? {}), isEditFromIssueView };
	}
	return meta;
};

/**
 * @deprecated Use the field specific hook instead. e.g. `useLabelsField`
 * For further information refer to https://hello.atlassian.net/wiki/spaces/JIE/pages/2286428038 or reach out to #issue-view-decomposition
 * @template InputShape - is the expected shape of the value for this field in the fieldValueStore
 * @template Meta - is any metadata required for the operation of this field (usually used by a custom saveField function)
 * @template ReturnType - is the return value of custom `saveField` and `saveById`
 * @template IdOnlyInputShape - is the type of the object with `id` property only to use it in `saveById` function. The name of `id` property can be different. For example: `accountId` or
 */
export const useEditField = <
	InputShape,
	Meta,
	ReturnType = undefined,
	IdOnlyInputShape = undefined,
>(
	fieldOptions: FieldOptions<InputShape, Meta, ReturnType, IdOnlyInputShape>,
): SaveFieldHook<InputShape, Meta, ReturnType, IdOnlyInputShape> => {
	const {
		fieldKey,
		issueId: issueIdFromFieldOptions,
		issueKey,
		onSubmit = noop,
		onSuccess = noop,
		onFailure = noop,
		shouldPreventIssueViewSoftRefresh = false,
	} = fieldOptions;

	const [, { fieldChanged, fieldChangeFailed, fieldChangeRequested }] =
		useIssueViewFieldUpdateEvents();
	const contextualIssueId = useContextualIssueIdForIssueKey(issueKey);
	const issueId = issueIdFromFieldOptions ?? contextualIssueId;
	const isEditFromIssueView = useIsEditFromIssueView;

	const onSubmitFn = useCallback(
		(oldValue: InputShape, newValue: InputShape, meta: Meta, event: UIAnalyticsEvent) => {
			onSubmit(oldValue, newValue, meta, event);
			issueId && fieldChangeRequested(issueId, fieldKey, newValue, meta);
		},
		[fieldChangeRequested, fieldKey, issueId, onSubmit],
	);
	const onSuccessFn = useCallback(
		async (fieldValue: InputShape, event: UIAnalyticsEvent, meta: Meta) => {
			onSuccess(fieldValue);
			issueId &&
				fieldChanged(
					issueId,
					fieldKey,
					fieldValue,
					undefined,
					fg('one_event_rules_them_all_fg')
						? getAdditionalProperties(meta, isEditFromIssueView(event))
						: meta,
				);

			if (!shouldPreventIssueViewSoftRefresh && !isEditFromIssueView(event)) {
				try {
					const reason = Reason.SoftRefresh;

					await jiraBridge.refreshIssuePageThrowable(issueKey, reason);
				} catch (error: unknown) {
					log.safeErrorWithoutCustomerData(
						'issue.fields.use-edit-field.refresh',
						error instanceof Error ? error.message : 'useEditField issue refresh failed',
					);
				}
			}
		},
		[
			fieldChanged,
			fieldKey,
			shouldPreventIssueViewSoftRefresh,
			isEditFromIssueView,
			issueId,
			issueKey,
			onSuccess,
		],
	);

	const onFailureFn = useCallback(
		(error: Error, failedValue: InputShape) => {
			onFailure(error, failedValue);
			issueId && fieldChangeFailed(issueId, fieldKey);
		},
		[fieldChangeFailed, fieldKey, issueId, onFailure],
	);

	return useEditFieldOriginal({
		...fieldOptions,
		onSubmit: onSubmitFn,
		onSuccess: onSuccessFn,
		onFailure: onFailureFn,
	});
};
