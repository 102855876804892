import type { NotificationType } from '@atlassian/jira-issue-adjustments/src/controllers.tsx';
import {
	conflictUimNotificationFlagProperties,
	uimMultipleAppsFlagProperties,
} from './constants.tsx';
import type { NotificationFlagProperties } from './types.tsx';

export const getNotificationFlagProperties = (
	notificationType: NotificationType,
): NotificationFlagProperties | null => {
	const notificationFlagPropertiesMap: Record<NotificationType, NotificationFlagProperties> = {
		MULTIPLE_APPS_CONFLICTS: conflictUimNotificationFlagProperties,
		// remove with the fg('uim_multiple_apps_detected_notification_remove') FF clean up
		MULTIPLE_APPS_IN_CONTEXT: uimMultipleAppsFlagProperties,
	};

	const notificationFlagProperties = notificationFlagPropertiesMap[notificationType];

	if (notificationFlagProperties === undefined) {
		return null;
	}

	return notificationFlagProperties;
};
