/** @jsx jsx */
import React from 'react';
import { styled, css, jsx } from '@compiled/react';
import Button, { type ButtonProps } from '@atlaskit/button';
import ExpandIcon from '@atlaskit/icon/glyph/chevron-down';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import Pulse from './pulse/index.tsx';
import { DoneButton } from './styled.tsx';
import type { Props } from './types.tsx';

const getButton = (categoryId: number) => {
	switch (categoryId) {
		case 2:
			return ToDoButton;
		case 3:
			return DoneButton;
		case 4:
			return InProgressButton;
		default:
			return DefaultButton;
	}
};

export const StatusButton = (props: Props) => {
	const {
		ariaLabel,
		isEditable,
		isOpen,
		status,
		targetRef,
		statusButtonRef,
		shouldFitContainer = true,
	} = props;
	// eslint-disable-next-line @typescript-eslint/no-shadow
	const Button = getButton(status.statusCategory.id);
	return (
		<div ref={targetRef}>
			<Tooltip position="top" content={status.name}>
				<Pulse status={status}>
					<Button
						id="issue.fields.status-view.status-button"
						ref={statusButtonRef}
						aria-label={ariaLabel}
						aria-expanded={isOpen}
						isDisabled={!isEditable}
						iconAfter={<ExpandIcon label="" />}
						testId="issue-field-status.ui.status-view.status-button.status-button"
						shouldFitContainer={shouldFitContainer}
						// eslint-disable-next-line @atlaskit/design-system/no-unsafe-style-overrides
						css={[
							buttonStyle,
							isVisualRefreshEnabled() && buttonStyleRefresh,
							isVisualRefreshEnabled() && fg('visual-refresh_drop_3') && buttonStyleRefreshDrop3,
						]}
						// When the popup is open we don't want to track the click on this button as it solely closes
						// the popup.
						// The logic here may look incorrect, but it turns out that when clicking this button the
						// `isOpen` toggle happens before the `onClick` handler that sends the press tracing.
						{...(fg('issue-jiv-18579-status-field-interaction-name')
							? {
									// When cleaning up we will need to disable the `jira/ufo/valid-labels` lint rule
									// because for this particular interaction we only want to track
									// opening the status popup.
									interactionName: isOpen ? 'issue.fields.status.popup.open' : undefined,
								}
							: {})}
					>
						{status.name}
					</Button>
				</Pulse>
			</Tooltip>
		</div>
	);
};

const buttonStyle = css({
	fontWeight: token('font.weight.semibold'),
});

const buttonStyleRefresh = css({
	fontWeight: token('font.weight.semibold'),
	'&::after': {
		border: 'none',
	},
});

const buttonStyleRefreshDrop3 = css({
	fontWeight: token('font.weight.medium'),
	'&::after': {
		border: 'none',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const InProgressComponentVisualRefresh = styled(Button)(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props: ButtonProps) =>
		!props.isSelected && {
			'&&:not(:active), &&:disabled': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				background: `${token('color.background.accent.blue.subtle', colors.B600)} !important`,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				color: `${token('color.text', colors.T30)} !important`,
			},
			'&&:hover:not(:active)': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				background: `${token('color.background.accent.blue.subtle', colors.B700)} !important`,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				color: `${token('color.text', colors.T30)} !important`,
			},
		},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const InProgressComponentCurrent = styled(Button)(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props: ButtonProps) =>
		!props.isSelected && {
			'&&:not(:active), &&:disabled': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				background: `${token('color.background.information.bold', colors.B400)} !important`,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				color: `${token('color.text.inverse', colors.N0)} !important`,
			},
			'&&:hover:not(:active)': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				background: `${token('color.background.information.bold.hovered', colors.B500)} !important`,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				color: `${token('color.text.inverse', colors.N0)} !important`,
			},
		},
);

const InProgressButton = componentWithCondition(
	() => isVisualRefreshEnabled() && fg('jira_nav4_beta_drop_1'),
	InProgressComponentVisualRefresh,
	InProgressComponentCurrent,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const ToDoButton = styled(Button)(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props: ButtonProps) =>
		!props.isSelected && {
			'&&:not(:active), &&:disabled': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				background: `${token('color.background.neutral', colors.N40)} !important`,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				color: `${token('color.text', colors.N800)} !important`,
			},
			'&&:hover:not(:active)': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				background: `${token('color.background.neutral.hovered', colors.N50)} !important`,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				color: `${token('color.text', colors.N800)} !important`,
			},
		},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const DefaultButton = styled(Button)(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props: ButtonProps) =>
		!props.isSelected && {
			'&&:not(:active), &&:disabled': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				background: `${token('color.background.neutral', colors.N20A)} !important`,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				color: `${token('color.text', colors.N400)} !important`,
			},
			'&&:hover:not(:active)': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				background: `${token('color.background.neutral.hovered', colors.N30A)} !important`,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				color: `${token('color.text', colors.N400)} !important`,
			},
		},
);
