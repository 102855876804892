import React, { memo, useMemo } from 'react';
import { styled } from '@compiled/react';
import CheckCircleIcon from '@atlaskit/icon/glyph/check-circle';
import CrossCircleIcon from '@atlaskit/icon/glyph/cross-circle';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { SummaryItem } from '@atlassian/jira-development-summary-common/src/ui/summary-item/index.tsx';
import { FormattedMessage } from '@atlassian/jira-intl';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import messages from './messages.tsx';
import type { Build as BuildType } from './types.tsx';

type BuildProps = {
	build: BuildType | undefined | null;
	onClick?: () => void;
};

export const areEqual = (previousProps: BuildProps, nextProps: BuildProps): boolean =>
	nextProps.build?.count === previousProps.build?.count &&
	nextProps.build?.lastUpdated === previousProps.build?.lastUpdated &&
	nextProps.build?.failedBuildCount === previousProps.build?.failedBuildCount &&
	nextProps.build?.successfulBuildCount === previousProps.build?.successfulBuildCount &&
	nextProps.build?.unknownBuildCount === previousProps.build?.unknownBuildCount &&
	nextProps.build?.url === previousProps.build?.url;

export const Build = memo<BuildProps>(({ build, onClick }: BuildProps) => {
	const { formatNumber, formatMessage } = useIntl();
	const { count = 0, failedBuildCount = 0, successfulBuildCount = 0 } = build ?? {};

	const { title, secondaryData } = useMemo(() => {
		let titleComponent;
		let icon;
		if (failedBuildCount > 0) {
			icon = (
				<CrossCircleIcon
					label="dev-panel-failed-build-icon"
					primaryColor={token('color.icon.danger', colors.R300)}
				/>
			);
			titleComponent = (
				<FormattedMessage
					{...messages.failingBuildsTitle}
					values={{
						bold: (chunks) => <BoldFont>{chunks}</BoldFont>,
						count: formatNumber(failedBuildCount),
					}}
				/>
			);
		} else if (successfulBuildCount > 0) {
			icon = (
				<CheckCircleIcon
					label="dev-panel-successful-build-icon"
					primaryColor={token('color.icon.success', colors.G300)}
				/>
			);
			titleComponent = (
				<FormattedMessage
					{...messages.successfulBuildsTitle}
					values={{
						bold: (chunks) => <BoldFont>{chunks}</BoldFont>,
						count: formatNumber(successfulBuildCount),
					}}
				/>
			);
		} else {
			icon = null;
			titleComponent = (
				<FormattedMessage
					{...messages.incompleteBuildsTitle}
					values={{
						bold: (chunks) => <BoldFont>{chunks}</BoldFont>,
						count: formatNumber(count),
					}}
				/>
			);
		}
		return {
			title: titleComponent,
			secondaryData: icon,
		};
	}, [count, failedBuildCount, formatNumber, successfulBuildCount]);

	const shownItemCount = useMemo(() => {
		let buildCountToBeShown;

		if (failedBuildCount > 0) {
			buildCountToBeShown = failedBuildCount;
		} else if (successfulBuildCount > 0) {
			buildCountToBeShown = successfulBuildCount;
		} else {
			buildCountToBeShown = count;
		}
		return buildCountToBeShown;
	}, [count, failedBuildCount, successfulBuildCount]);

	if (build && count > 0) {
		return (
			<SummaryItem
				title={title}
				secondaryData={secondaryData}
				href={undefined}
				onClick={onClick}
				itemType="build"
				itemCount={shownItemCount}
				oneClickUrl={build.url}
				oneClickToolTipContent={formatMessage(messages.tooltip)}
				data-testid="development-summary-build.ui.summary-item"
			/>
		);
	}
	return <></>;
}, areEqual);

export default Build;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BoldFont = styled.span({
	fontWeight: 500,
});
