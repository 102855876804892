/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import { bind } from 'bind-event-listener';
import { useIntl } from 'react-intl-next';

import { findOverflowScrollParent } from '@atlaskit/editor-common/ui';
import { type Fragment } from '@atlaskit/editor-prosemirror/model';
import { editorExperiment } from '@atlaskit/tmp-editor-statsig/experiments';
import type { IDMap } from '@atlassian/ai-model-io/convert-prosemirror-to-markdown/serializer';
import { isMarkdownEndingWithCustomTag } from '@atlassian/ai-model-io/utils/is-markdown-with-custom-tag';
import { LoadingScreen } from '@atlassian/generative-ai-modal/screens/Loading';
import {
	scrollMarginStyles,
	scrollTopMargin,
} from '@atlassian/generative-ai-modal/styles/FloatingContainer';

import { useAnalyticsFlowEventsQueue } from '../../analytics/analytics-flow/analyticsFlowUtils';
import { useFireAIAnalyticsEvent } from '../../analytics/utils';
import { getTranslatedPromptLabel } from '../../config-items/config-items';
import { convertPMFragmentToPreview } from '../../ui/convert-markdown-to-preview';
import type { ContextStatistics, LatestPrompt, PromptTrigger } from '../get-ai-experience-service';
import { getPMFragmentWithFallback } from '../get-pm-fragment-with-fallback';
import { useAIExperienceCommonDataContext } from '../useAIExperienceCommonData';

import { useAIUsageDisclaimer } from './utils/use-ai-usage-disclaimer';
import { useLocalizedLatestPrompt } from './utils/useLocalizedLatestPrompt';
import { usePromptStatistics } from './utils/usePromptStatistics';

// Selectors will be used for editorView.dom.closest() to track
// the scroll parent element and scrollable content height
const AK_EDITOR_CONTENT_AREA_SELECTOR = '.ak-editor-content-area';

export function LoadingScreenWithLogic({
	parentPresetPromptLabel,
	childShortTitle,
	title,
	latestPrompt,
	promptTrigger,
	refinementCount,
	latestPromptRetried,
	markdown,
	//TODO: AI Button experiment cleanup - platform_editor_ai_ai_button_block_elements
	fragment,
	idMap,
	contextStatistics,
}: {
	/**
	 * Label for the parent if the user selects a submenu via a parent (`Change tone` -> `Neutral`)
	 * If the user selects a submenu directly, this will be empty (search and select `Change tone to Neutral`)
	 */
	parentPresetPromptLabel?: string;
	/**
	 * Short title for the submenu if the user selects a submenu via a parent (`Change tone` -> `Neutral`)
	 * If the user selects a submenu directly, this will be empty (search and select `Change tone to Neutral`)
	 */
	childShortTitle?: string;
	title: string;
	promptTrigger?: PromptTrigger;
	/**
	 * This is used to track the number of times the user has refined
	 * (clicked by Refine button in the Preview screen) the prompt.
	 */
	refinementCount?: number;
	latestPromptRetried: boolean;
	latestPrompt?: LatestPrompt;
	markdown?: string;
	//TODO: AI Button experiment cleanup - platform_editor_ai_ai_button_block_elements
	fragment?: Fragment;
	idMap?: IDMap;
	contextStatistics?: ContextStatistics;
}) {
	const aiExperienceCommonData = useAIExperienceCommonDataContext();
	const {
		configItem,
		editorView,
		providerFactory,
		sendToAIExperienceMachine,
		editorPluginAIProvider,
	} = aiExperienceCommonData;
	const schema = editorView.state.schema;

	const { formatMessage } = useIntl();
	const disclaimer = useAIUsageDisclaimer({ editorPluginAIProvider });

	const presetPromptLabel = getTranslatedPromptLabel(configItem, formatMessage);

	const fireAIAnalyticsEvent = useFireAIAnalyticsEvent();
	const [preview, setPreview] = React.useState<JSX.Element>();
	const [hasPreview, setHasPreview] = React.useState<boolean>(false);

	const containerRef = React.useRef<HTMLDivElement>(null);

	// raf is used for debouncing, if useEffect is called
	// multiple times before raf is run this prevents rendering
	// multiple times if we receive streams very quickly
	const raf = React.useRef<number | undefined>();
	const errorReported = React.useRef<boolean>(false);

	/**
	 * scrollParentRef is for
	 * storing the scroll parent element once its found
	 * to be re-used in calculations
	 */
	const scrollParentRef = React.useRef<HTMLElement | null>(null);
	const initialScrollTop = React.useRef<number>(0);
	const modalOffsetTop = React.useRef<number>(0);
	const isUserScroll = React.useRef<boolean>(false);
	/**
	 * isProgrammaticScroll is set to true initially
	 * to detect any initial changes on render which affect
	 * scroll position. There will be multiple scroll events
	 * within a single user scroll so it will be set to false
	 * accordingly within a single scroll action.
	 */
	const isProgrammaticScroll = React.useRef<boolean>(true);
	const isModalAtTop = React.useRef<boolean>(false);

	const scrollModalAlignBottom = React.useCallback(
		(modalEl: HTMLElement, scrollParentEl: HTMLElement) => {
			// We set isProgrammaticScroll to true to flag it as a non-user scroll.
			isProgrammaticScroll.current = true;
			scrollParentEl.scroll({
				top:
					initialScrollTop.current +
					modalOffsetTop.current +
					modalEl.scrollHeight -
					scrollParentEl.offsetHeight,
				behavior: 'instant',
			});
		},
		[],
	);

	const scrollModalToTop = React.useCallback((scrollParentEl: HTMLElement) => {
		// We set isProgrammaticScroll to true to flag it as a non-user scroll.
		isProgrammaticScroll.current = true;
		scrollParentEl.scroll({
			top: initialScrollTop.current + modalOffsetTop.current - scrollTopMargin,
			behavior: 'instant',
		});
	}, []);

	React.useEffect(() => {
		/**
		 * Set up ResizeObserver to detect cases where the editor content grows,
		 * as it will normally trigger a scroll event due to scrollHeight and scrollTop changes.
		 * We set isProgrammaticScroll to true to flag it as a non-user scroll.
		 */
		const editorContentArea: HTMLElement | null = editorView.dom.closest(
			AK_EDITOR_CONTENT_AREA_SELECTOR,
		);
		if (!editorContentArea) {
			return;
		}
		const resizeObserver = new ResizeObserver(() => {
			isProgrammaticScroll.current = true;
		});
		resizeObserver.observe(editorContentArea);
		return () => resizeObserver.unobserve(editorContentArea);
	}, [editorView]);

	React.useEffect(() => {
		/**
		 * Setup init values to calculate scroll distance and
		 * scroll the modal into view if it is not already visible.
		 */
		const modalEl = containerRef.current;
		if (!modalEl) {
			return;
		}
		const scrollParentEl: HTMLElement | false = findOverflowScrollParent(modalEl);
		if (!scrollParentEl) {
			return;
		}
		scrollParentRef.current = scrollParentEl;

		// Calculate the offset of the modal from the top of editor
		modalOffsetTop.current =
			modalEl.getBoundingClientRect().top - scrollParentEl.getBoundingClientRect().top;

		// Calculate initial scroll position of editor
		initialScrollTop.current = scrollParentEl.scrollTop;

		// Calculate position of the bottom of the modal from top of editor
		const modalBottomOffsetTop = modalOffsetTop.current + modalEl.scrollHeight;

		if (modalBottomOffsetTop < 0) {
			// Edge case: if modal above the top of the editor
			scrollModalToTop(scrollParentEl);
		} else if (modalOffsetTop.current > scrollParentEl.offsetHeight) {
			/**
			 * Edge case: if modal is below the bottom of the editor
			 * Call scrollModalAlignBottom to move modal into view.
			 */
			scrollModalAlignBottom(modalEl, scrollParentEl);
		}

		const unbind = bind(scrollParentEl, {
			type: 'scroll',
			listener: () => {
				if (!isProgrammaticScroll.current) {
					isUserScroll.current = true;
					unbind();
				}
				isProgrammaticScroll.current = false;
			},
		});
		return unbind;
	}, [scrollModalToTop, scrollModalAlignBottom]);

	function scrollModalIntoView() {
		/**
		 * Early exits:
		 * - If user has manually scrolled, stop auto-scrolling
		 * - If modal has reached the top of the editor, no more calculations or scrolling needed
		 */
		if (isUserScroll.current || isModalAtTop.current) {
			return;
		}
		const modalEl = containerRef.current;
		if (!modalEl) {
			return;
		}
		const scrollParentEl = scrollParentRef.current;
		if (!scrollParentEl) {
			return;
		}

		// Check if modal is already fully visible
		const isModalFullyVisible =
			scrollParentEl.offsetHeight > modalOffsetTop.current + modalEl.scrollHeight;

		/**
		 * Check if modal height exceeds scroll parent's height
		 * Scroll margin is used to prevent the modal from being scrolled to the top
		 * and should match scroll margin in other modals e.g. from
		 * packages/editor/editor-plugin-ai/src/ui/components/FloatingContainer/styles.ts
		 */
		const isModalFullHeight = scrollParentEl.offsetHeight - scrollTopMargin < modalEl.scrollHeight;

		if (isModalFullyVisible) {
			return;
		}

		if (isModalFullHeight) {
			scrollModalToTop(scrollParentEl);
			isModalAtTop.current = true;
			return;
		}

		scrollModalAlignBottom(modalEl, scrollParentEl);
	}

	React.useEffect(() => {
		// only run the callback if the raf has not been set, and then
		// reset raf to undefined
		if (raf.current === undefined) {
			raf.current = window.requestAnimationFrame(() => {
				let _preview;
				raf.current = undefined;

				if (!markdown || markdown === '') {
					// When there is no streamed preview content -- set the preview to
					// undefined.
					// This allows a nicer loading screen (rather than showing an empty paragraph).
					_preview = undefined;
				} else {
					const { pmFragment } =
						//TODO: AI Button experiment cleanup - platform_editor_ai_ai_button_block_elements
						editorExperiment('platform_editor_ai_ai_button_block_elements', 'test') && fragment
							? { pmFragment: fragment }
							: getPMFragmentWithFallback({
									markdown: markdown,
									schema,
									source: 'loading',
									fireAIAnalyticsEvent: (event) => {
										if (!errorReported.current) {
											errorReported.current = true;
											fireAIAnalyticsEvent(event);
										}
									},
									idMap: idMap!,
								});

					/**
					 * Hold off on setting the preview if we appear to be receiving
					 * a custom tag through our stream. This is to hide raw tags from
					 * users until the tags are complete and can be rendered back into nodes.
					 */
					if (isMarkdownEndingWithCustomTag(markdown)) {
						return;
					}
					_preview = convertPMFragmentToPreview({
						schema,
						pmFragment,
						showTelepointer: true,
						providerFactory,
					});
					setPreview(_preview);
					setHasPreview(true);
					window.requestAnimationFrame(() => {
						scrollModalIntoView();
					});
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [markdown]);

	React.useEffect(() => {
		return () => {
			// if the component is unmounted before the raf callback
			// has run, we cancel it
			if (raf.current) {
				window.cancelAnimationFrame(raf.current);
			}
		};
	}, []);

	// preset prompt or base generate / interrogation
	const localizedLatestPrompt = useLocalizedLatestPrompt(latestPrompt);
	const promptStatistics = usePromptStatistics(localizedLatestPrompt);

	const analyticsFlow = useAnalyticsFlowEventsQueue({
		stepName: 'loading',
		attributes: {
			promptType: promptTrigger,
			latestPromptRetried,
			refinementCount,
			modelIO: {
				contextStatistics,
				userInputCharacterCount: promptStatistics?.totalCharacterCount,
				userInputNodeCounts: promptStatistics?.nodeCounts,
				userInputLinksCount: promptStatistics?.linksCount,
			},
		},
	});

	const previewBecameAvailable = React.useRef<number>();

	if (!previewBecameAvailable.current && hasPreview) {
		previewBecameAvailable.current = performance.now();
	}

	React.useEffect(() => {
		const noPreviewAvailable = performance.now();

		return () => {
			if (!previewBecameAvailable.current) {
				return;
			}

			analyticsFlow.addAttributes({
				durations: {
					timeSpentWithoutPartialPreview: previewBecameAvailable.current - noPreviewAvailable,
					timeSpentWithPartialPreview: performance.now() - previewBecameAvailable.current,
				},
			});
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
		<div ref={containerRef} css={scrollMarginStyles}>
			<LoadingScreen
				parentPresetPromptLabel={parentPresetPromptLabel}
				childShortTitle={childShortTitle}
				presetPromptLabel={presetPromptLabel}
				latestPrompt={localizedLatestPrompt}
				agent={configItem?.agent}
				title={title}
				preview={preview}
				providerFactory={providerFactory}
				disclaimer={disclaimer}
				onCancel={() => {
					sendToAIExperienceMachine({ type: 'enable discard' });
				}}
				schema={schema}
			/>
		</div>
	);
}
