import React from 'react';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import type { Area } from '@atlassian/jira-issue-view-common-types/src/connect-field-type.tsx';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { canUseServiceDeskAgentFeaturesSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/permissions-selector.tsx';
import { RespondersGlance } from '@atlassian/jira-responders/src/async.tsx';
import type { Props } from './types.tsx';

export const RespondersGlanceContainer = ({
	isVisible = true,
	onOpenPanel,
	fieldId,
	showPinButton,
}: Props) => (
	<ErrorBoundary>
		{isVisible && (
			<RespondersGlance onOpenPanel={onOpenPanel} fieldId={fieldId} showPinButton={showPinButton} />
		)}
	</ErrorBoundary>
);

export default connect(
	(state, ownPropsOnMount: { area: Area }) => ({
		isVisible:
			ff('responders-field-in-all-projects-issues-ui') ||
			canUseServiceDeskAgentFeaturesSelector(state),
		showPinButton: getShowPinButton(ownPropsOnMount.area),
	}),
	{},
)(RespondersGlanceContainer);
