import React, { useState, type ReactNode, useEffect, useCallback } from 'react';
import { styled } from '@compiled/react';
import { IconButton } from '@atlaskit/button/new';
import Button from '@atlaskit/button/standard-button';
import ShareIconNew from '@atlaskit/icon/core/share';
import ShareIcon from '@atlaskit/icon/glyph/share';
import Popup from '@atlaskit/popup';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';
import { WarningContent } from './warning-content/index.tsx';

const ConditionalTooltip = ({ children }: { children: ReactNode }) => {
	const { formatMessage } = useIntl();

	return (
		<Tooltip content={formatMessage(messages.shareTriggerButtonText)} position="top">
			{children}
		</Tooltip>
	);
};

const PreShareView = ({
	triggerButtonStyle,
	loading,
	error,
	emailSettings,
	hipaaStatus,
	renderCustomTriggerButton,
	getEmailSettings,
	getHipaaStatus,
	getUserPermission,
	isAutoOpenDialog = false,
	onDialogClose,
	onTriggerButtonClick,
	shareShortcutTriggerCount,
}: Props) => {
	const { formatMessage } = useIntl();
	const [isOpen, setIsOpen] = useState(isAutoOpenDialog);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	// Used to invoke a re-render when isAutoOpenDialog has not changed but the shortcut has been pressed again.
	useEffect(() => {
		setIsOpen(isAutoOpenDialog);
	}, [isAutoOpenDialog, shareShortcutTriggerCount]);

	const onTriggerClick = useCallback(
		(trigger: 'PreShareView' | 'RenderCustomTriggerPreShareView') => {
			const analyticsEvent = createAnalyticsEvent({
				action: 'PreShareView Button Clicked',
				actionSubject: 'Share',
			});
			setIsOpen(!isOpen);
			if (!isOpen) {
				getUserPermission();
				getEmailSettings();
				getHipaaStatus();
			}
			fireUIAnalytics(analyticsEvent, trigger);
			if (onTriggerButtonClick) {
				onTriggerButtonClick();
			}
		},
		[
			createAnalyticsEvent,
			isOpen,
			onTriggerButtonClick,
			getUserPermission,
			getEmailSettings,
			getHipaaStatus,
		],
	);

	return (
		<Popup
			onClose={() => {
				setIsOpen(false);
				onDialogClose && onDialogClose();
			}}
			zIndex={layers.modal}
			content={() => (
				<ShortcutScope>
					{/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */}
					<DialogLayout data-testid="share-button.ui.pre-share-view.warningModal">
						{loading ? (
							<Spinner testId="share-button.ui.pre-share-view.loading" size="medium" />
						) : (
							<WarningContent
								isEmailEnabled={emailSettings?.isEmailEnabled}
								canUserEnableEmail={!!emailSettings?.canUserEnableEmail}
								isUserHIPAA={!!hipaaStatus?.isCompliant}
							/>
						)}
						{error && (
							<h1>
								<FormattedMessage {...messages.errorMessage} />
							</h1>
						)}
					</DialogLayout>
				</ShortcutScope>
			)}
			isOpen={isOpen}
			placement="left-start"
			trigger={(triggerProps) =>
				renderCustomTriggerButton ? (
					renderCustomTriggerButton(
						{
							isSelected: isOpen,
							onClick: () => onTriggerClick('RenderCustomTriggerPreShareView'),
						},
						triggerProps,
					)
				) : (
					<ConditionalTooltip>
						<UFOSegment name="issue-pre-share-button">
							{isVisualRefreshEnabled() ? (
								<IconButton
									testId="share-button.ui.pre-share-view.button"
									isDisabled={loading}
									label={formatMessage(messages.shareTriggerButtonText)}
									{...triggerProps}
									appearance="default"
									icon={() => (
										<ShareIconNew
											spacing="spacious"
											label={
												triggerButtonStyle === 'icon-with-text'
													? ''
													: formatMessage(messages.shareTriggerButtonText)
											}
											color={token('color.icon', '#44546F')}
										/>
									)}
									onClick={() => onTriggerClick('PreShareView')}
								/>
							) : (
								<Button
									testId="share-button.ui.pre-share-view.button"
									// @ts-expect-error - TS2322 - Type '{ children: string | null; appearance: "subtle"; iconBefore: Element; onClick: () => void; ref: Ref<HTMLElement>; 'aria-controls'?: string | undefined; 'aria-expanded': boolean; 'aria-haspopup': boolean; testId: string; disabled: boolean; }' is not assignable to type 'IntrinsicAttributes & ButtonProps & RefAttributes<HTMLElement>'.
									disabled={loading}
									{...triggerProps}
									appearance="subtle"
									iconBefore={
										<ShareIcon
											size="medium"
											label={
												triggerButtonStyle === 'icon-with-text'
													? ''
													: formatMessage(messages.shareTriggerButtonText)
											}
											primaryColor={token('color.icon', '#44546F')}
										/>
									}
									onClick={() => onTriggerClick('PreShareView')}
								>
									{triggerButtonStyle === 'icon-with-text'
										? formatMessage(messages.shareTriggerButtonText)
										: null}
								</Button>
							)}
						</UFOSegment>
					</ConditionalTooltip>
				)
			}
		/>
	);
};

export default PreShareView;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DialogLayout = styled.div<{
	children?: ReactNode;
	['data-testid']: string;
}>({
	textAlign: 'center',
	padding: token('space.200', '16px'),
});
