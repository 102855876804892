import React, { useMemo, type ComponentPropsWithoutRef, type ComponentType } from 'react';

import { styled } from '@compiled/react';
import { graphql } from 'react-relay';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import {
	FIELD_HEADING_TITLE_COMPONENT_SELECTOR,
	FIELD_WRAPPER_COMPONENT_SELECTOR,
	MULTILINE_FIELD_HEADING_TITLE_COMPONENT_SELECTOR,
} from '@atlassian/jira-issue-field-heading/src/styled.tsx';
import { useQueryLoaderOnIntent } from '@atlassian/jira-issue-hooks/src/services/use-query-loader-on-intent/index.tsx';
import { HIDE_DONE_SUBTASKS_FILTER } from '@atlassian/jira-issue-shared-types/src/common/types/user-preferences-type.tsx';
import { useUserPreferencesActions } from '@atlassian/jira-issue-user-preference-services/src/main.tsx';
import { issueViewLayers as layers } from '@atlassian/jira-issue-view-layers/src/index.tsx';
import { SORT_ASC } from '@atlassian/jira-native-issue-table/src/common/constants.tsx';
import type { expandableHeader_prefetch_ConfigurableChildIssuesPanelQuery } from '@atlassian/jira-relay/src/__generated__/expandableHeader_prefetch_ConfigurableChildIssuesPanelQuery.graphql.ts';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { SORT_OPTION_RANK } from '../../../model/types.tsx';

import {
	JQLBuilder,
	createConfigurableChildIssuesPanelQueryVariables,
} from '../../configurable-child-issues-panel/src/utils/index.tsx';
import type { GeneralProps } from '../types.tsx';
import {
	groupBorderRadius,
	subTitleStyledSelectorName,
	titleStyledSelectorName,
	SUB_TITLE_STYLED_COMPONENT_SELECTOR,
	TITLE_STYLED_COMPONENT_SELECTOR,
	HEADER_TITLE_DATA_TEST_ID,
} from '../constants.tsx';
import type { ExpandableHeaderProps, StickyHeaderProps } from './types.tsx';

export const ExpandableHeader = ({
	id,
	isOpen,
	subTitle,
	title,
	onToggle,
	renderHeaderActions,
	stickyHeaderPosition,
	filterSubtasks,
	projectId,
	parentIssueTypeId,
	innerRef,
}: ExpandableHeaderProps) => {
	const cloudId = useCloudId();
	const issueKey = useIssueKey();
	const [, { getUserPreferenceValue }] = useUserPreferencesActions();
	const hideDone = useMemo(
		() => getUserPreferenceValue(HIDE_DONE_SUBTASKS_FILTER),
		[getUserPreferenceValue],
	);
	const testId =
		id === null || id === undefined
			? undefined
			: {
					'data-testid': `issue-view-common-views.child-issues-panel.collapsible-child-issues.${id}`,
				};

	const [, prefetchOptions, abortPrefetchOptions] =
		// eslint-disable-next-line @atlassian/relay/must-use-inline
		useQueryLoaderOnIntent<expandableHeader_prefetch_ConfigurableChildIssuesPanelQuery>(graphql`
			query expandableHeader_prefetch_ConfigurableChildIssuesPanelQuery(
				$cloudId: ID!
				$issueSearchInput: JiraIssueSearchInput!
				$namespace: String
				$viewId: String
				$filterId: String
				$fieldSetIds: [String!]!
				$amountOfColumns: Int!
				$shouldQueryFieldSetsById: Boolean!
				$projectId: ID
				$issueTypeId: ID
				$fieldSetsInput: JiraIssueSearchFieldSetsInput
			) {
				# eslint-disable-next-line @atlassian/relay/must-colocate-fragment-spreads
				...ui_issueViewCommonViews_ConfigurableChildIssuesPanelWithFragment
			}
		`);

	return (
		<Header
			aria-label={title}
			onClick={onToggle}
			tabIndex={0}
			{...testId}
			stickyHeaderPosition={stickyHeaderPosition}
			ref={innerRef}
			role="button"
			onPointerEnter={() =>
				prefetchOptions(
					createConfigurableChildIssuesPanelQueryVariables({
						cloudId,
						jql: JQLBuilder({
							issueKey,
							hideDone,
							sortColumn: SORT_OPTION_RANK,
							sortDirection: SORT_ASC,
							filterSubtasks,
						}),
						fieldSetIds: [],
						projectId,
						issueTypeId: parentIssueTypeId,
					}),
				)
			}
			onPointerLeave={(e: { pointerType: string }) =>
				e.pointerType === 'mouse' && abortPrefetchOptions()
			}
		>
			<Title data-testid={HEADER_TITLE_DATA_TEST_ID}>{title}</Title>
			{subTitle !== undefined && !isOpen && <SubTitle aria-hidden="true">{subTitle}</SubTitle>}
			{typeof renderHeaderActions === 'function' && (
				<HeaderRightAligned isOpen={isOpen}>{renderHeaderActions()}</HeaderRightAligned>
			)}
		</Header>
	);
};

const Title = (props: ComponentPropsWithoutRef<typeof TitleStyledComponent>) => (
	<TitleStyledComponent data-component-selector={titleStyledSelectorName} {...props} />
);

const SubTitle = (props: ComponentPropsWithoutRef<typeof SubTitleStyledComponent>) => (
	<SubTitleStyledComponent data-component-selector={subTitleStyledSelectorName} {...props} />
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const TitleStyledComponent = styled.strong({
	color: token('color.text.subtle', '#44546F'),
	font: token('font.body'),
	fontWeight: token('font.weight.medium'),
	paddingRight: token('space.050', '4px'),
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	maxWidth: '60%',
	flexShrink: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const SubTitleStyledComponent = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N300),
	font: token('font.body.UNSAFE_small'),
	paddingRight: token('space.100', '8px'),
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	paddingTop: '1px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'@-moz-document url-prefix()': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		'&&': {
			marginBottom: token('space.negative.025', '-2px'),
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @typescript-eslint/consistent-type-assertions -- To migrate as part of go/ui-styling-standard
const HeaderRightAligned = styled.div({
	marginLeft: 'auto',
	display: 'flex',
}) as ComponentType<GeneralProps>;

const innerHeadingPaddingStyle = {
	padding: `${token('space.100', '8px')} ${token('space.150', '12px')} ${token('space.100', '8px')} ${token('space.150', '12px')}`,
	boxSizing: 'border-box',
	[`${FIELD_HEADING_TITLE_COMPONENT_SELECTOR}, ${MULTILINE_FIELD_HEADING_TITLE_COMPONENT_SELECTOR}`]:
		{
			color: token('color.text.subtle', colors.N500),
		},
	[FIELD_WRAPPER_COMPONENT_SELECTOR]: {
		marginBottom: token('space.150', '12px'),
	},
	[`> div > ${FIELD_WRAPPER_COMPONENT_SELECTOR}:last-child`]: {
		marginBottom: 0,
	},
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const SummaryWithPadding = styled.summary(innerHeadingPaddingStyle);

const borderStyle = `1px solid ${token('color.border', colors.N40)}`;
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledInnerHeaderPadding = styled(SummaryWithPadding)<StickyHeaderProps>({
	display: 'flex',
	paddingTop: token('space.150', '12px'),
	paddingBottom: token('space.150', '12px'),
	height: '48px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderRadius: `${groupBorderRadius}px`,
	borderTop: borderStyle,

	borderBottom: borderStyle,
	border: borderStyle,
	alignItems: 'center',
	position: 'sticky',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	top: ({ stickyHeaderPosition }) => (stickyHeaderPosition ? `${stickyHeaderPosition}px` : 0),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: `${token('elevation.surface.overlay', colors.N0)}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: layers.groupStickyHeader,
	// all conditions to make button visible
	'&:hover, &:focus, &:focus-within': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('elevation.surface.overlay.hovered', colors.N20),
		cursor: 'pointer',
	},
	'&:active': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('elevation.surface.overlay.pressed', colors.B50),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		borderColor: token('color.border', colors.B300),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		[`${SUB_TITLE_STYLED_COMPONENT_SELECTOR}, ${TITLE_STYLED_COMPONENT_SELECTOR}, svg`]: {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			color: `${token('color.text', colors.B300)} !important`,
		},
	},
	'&::-webkit-details-marker': {
		display: 'none',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @typescript-eslint/consistent-type-assertions -- To migrate as part of go/ui-styling-standard
const Header = styled(StyledInnerHeaderPadding)({
	'&:focus': {
		outline: `${token('border.width.outline', '2px')} solid ${token(
			'color.border.focused',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			colors.B200,
		)}`,
		outlineOffset: '-2px',
	},
}) as ComponentType<StickyHeaderProps>;
