import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import type { Dispatch } from 'redux';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import { useFragment, graphql } from 'react-relay';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { Flex, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { ScreenReaderText } from '@atlassian/jira-accessibility/src/common/ui/screenreader-text/index.tsx';
import {
	CORE_PROJECT,
	SOFTWARE_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import {
	categoryIdForStatusCategory,
	DONE,
} from '@atlassian/jira-common-constants/src/status-categories.tsx';
import ExperienceContext from '@atlassian/jira-common-experience-tracking-viewing/src/view/common/context/index.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import {
	TASK_FAIL,
	TASK_SUCCESS,
} from '@atlassian/jira-experience-tracker/src/common/constants.tsx';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import type FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { getUpdateAnalyticsFlowHelper } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/index.tsx';
import {
	useIssueKey,
	useProjectKey as useProjectKeyDeprecated,
	useAnalyticsSource,
	useIsAiEnabledForIssue,
} from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useSearchCustomFieldKeys } from '@atlassian/jira-issue-field-base/src/services/custom-field-key-service/index.tsx';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import { useFlaggedField } from '@atlassian/jira-issue-field-flagged/src/services/main.tsx';
import { ResolutionField } from '@atlassian/jira-issue-field-resolution/src/main.tsx';
import { useResolutionField } from '@atlassian/jira-issue-field-resolution/src/services/index.tsx';
import type {
	StatusValue,
	StatusTransition,
} from '@atlassian/jira-issue-field-status/src/common/types.tsx';
import type { OnStatusChangeSuccess } from '@atlassian/jira-issue-field-status/src/services/status-service/types.tsx';
import { ConnectedImproveIssueQuickAddItemWithStores } from '@atlassian/jira-issue-improve-issue-dropdown/src/index.tsx';
import { sendExperienceAnalytics } from '@atlassian/jira-issue-view-analytics/src/controllers/send-experience-analytics/index.tsx';
import type { FieldOptions } from '@atlassian/jira-issue-view-common-types/src/connect-field-type.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import { STATUS } from '@atlassian/jira-issue-view-configurations/src/index.tsx';
import { withEditExperienceTracker } from '@atlassian/jira-issue-view-experience-tracking/src/edit-experience/index.tsx';
import { withViewExperienceTracker } from '@atlassian/jira-issue-view-experience-tracking/src/view-experience/index.tsx';
import { IssueViewResolutionField } from '@atlassian/jira-issue-view-layout-resolution-field/src/ui/index.tsx';
import { toComponentValueShape } from '@atlassian/jira-issue-view-layout-status-field/src/ui/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { isCompletedLoadingSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';
import {
	fieldEditCancel,
	type FieldEditCancelAction,
} from '@atlassian/jira-issue-view-store/src/issue-field/state/actions/field-actions.tsx';
import {
	type FieldSaveRequestAction,
	fieldSaveSuccess,
	fieldUpdated,
	type FieldUpdatedAction,
} from '@atlassian/jira-issue-view-store/src/issue-field/state/actions/field-save-actions.tsx';
import {
	useWorkflowTransitionsActions,
	useWorkflowTransitionsTransitionInProgress,
} from '@atlassian/jira-issue-workflow-transitions-services/src/main.tsx';
import {
	FLAGGED_CF_TYPE,
	HIERARCHY_LEVEL_SAME_LEVEL,
	STATUS_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import { useIsPremium } from '@atlassian/jira-platform-react-hooks-use-ai-opt-in/src/index.tsx';
import { useAnalyticsEvents, fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import {
	useProjectType,
	useProjectKey,
	useApplication,
	useEdition,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import { StatusKey } from '@atlassian/jira-providers-issue/src/model/issue-system-fields.tsx';
import type { view_issueViewFoundation_StatusView$key } from '@atlassian/jira-relay/src/__generated__/view_issueViewFoundation_StatusView.graphql';
import type { StatusDetails } from '@atlassian/jira-shared-types/src/rest/jira/status.tsx';
import { AsyncQuickApprovalsButton } from '@atlassian/jira-software-quick-approvals-panel/src/ui/approval-button/async.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import ActionsMenu from './actions/index.tsx';
import FlaggedField from './flagged-field/view.tsx';
import { messages } from './messages.tsx';
import StatusFieldWithRefWrapper from './status-field-with-wrapper/index.tsx';
import { getStatusInitialValue } from './utils.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type OwnProps = Record<any, any>;
type StateProps = {
	isEditable: boolean;
};
type DispatchProps = {
	onStatusChanged: OnStatusChangeSuccess;
	onEditCancel: () => void;
};
type RelayProps = {
	// TODO Decomp BENTO-12514 - add useFragment to this component and replace this prop with more specific fragment key
	// fragmentKey at this stage as a prop, is passed in as MainIssueAggDataFragment
	// We change that here type wise, to be more specific view_issueViewFoundation_StatusView$key
	fragmentKey?: view_issueViewFoundation_StatusView$key | null;
};
export type Props = OwnProps & StateProps & DispatchProps & RelayProps;
type Action = FieldSaveRequestAction | FieldUpdatedAction | FieldEditCancelAction;

export const StatusView = ({ isEditable, onStatusChanged, onEditCancel, fragmentKey }: Props) => {
	const { formatMessage } = useIntl();
	const issueKeyOld = useIssueKey();
	const projectKeyDeprecated = useProjectKeyDeprecated();
	const projectType = useProjectType(projectKeyDeprecated);
	const experienceContext = useContext(ExperienceContext);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const isPremium = fg('jira-ai-issue-view-improve-issues-button') ? useIsPremium() : false;

	const isAiEnabled = fg('jira-ai-issue-view-improve-issues-button')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useIsAiEnabledForIssue()
		: false;

	// eslint-disable-next-line @atlassian/relay/query-restriction
	const data = useFragment<view_issueViewFoundation_StatusView$key>(
		graphql`
			fragment view_issueViewFoundation_StatusView on JiraIssue
			@argumentDefinitions(
				issueViewRelayResolutionFieldFlag: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/relay-migration-issue-fields-resolution.relayprovider"
				}
				issueViewRelayStatusFieldFlag: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/relay-migration-issue-fields-status.relayprovider"
				}
				issueViewRelayStatusPanelFlag: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/relay-migration-issue-fields-status-panel.relayprovider"
				}
			) {
				resolutionField @include(if: $issueViewRelayResolutionFieldFlag) {
					...ui_issueViewLayoutResolutionField_IssueViewResolutionField

					fieldConfig {
						isEditable
					}
					resolution {
						# Check for the presence of a resolution
						__typename
					}
				}
				statusField @include(if: $issueViewRelayStatusPanelFlag) {
					status {
						id
						name
						# eslint-disable-next-line @atlassian/relay/unused-fields
						statusId
						# eslint-disable-next-line @atlassian/relay/unused-fields
						description
						statusCategory {
							id
							statusCategoryId
							# eslint-disable-next-line @atlassian/relay/unused-fields
							key
							name
							# eslint-disable-next-line @atlassian/relay/unused-fields
							colorName
						}
					}
				}
				...ui_issueViewLayoutStatusField_IssueViewStatusField
					@include(if: $issueViewRelayStatusFieldFlag)
			}
		`,
		fragmentKey ?? null,
	);

	let initialValue: StatusValue | undefined;

	if (ff('relay-migration-issue-fields-status_qg82c')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		initialValue = useMemo(
			() => toComponentValueShape(data?.statusField?.status),
			[data?.statusField?.status],
		);
	} else {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const [statusFieldValue] = useFieldValue({ issueKey: issueKeyOld, fieldKey: STATUS });
		// eslint-disable-next-line react-hooks/rules-of-hooks
		initialValue = useMemo(() => getStatusInitialValue(statusFieldValue), [statusFieldValue]);
	}

	const [{ value: resolutionFieldValue, fieldConfig: resolutionFieldConfig }] = useResolutionField({
		issueKey: issueKeyOld,
	});
	const resolution = resolutionFieldValue?.name;
	const transitionInProgress = useWorkflowTransitionsTransitionInProgress();
	const [, workflowTransitionsActions] = useWorkflowTransitionsActions();

	// Show the field if we have a resolution, or if the field is editable and the status is done
	const shouldShowResolutionField =
		data?.resolutionField?.resolution ||
		(data?.resolutionField?.fieldConfig?.isEditable &&
			data.statusField?.status?.statusCategory?.statusCategoryId ===
				categoryIdForStatusCategory(DONE).toString());

	// This is needed for JSW's monitoring of Issue Hierarchy in Next-gen projects
	const [sameLevel] = useFieldValue({
		issueKey: issueKeyOld,
		fieldKey: HIERARCHY_LEVEL_SAME_LEVEL,
	});
	const issueHierarchyLevel = sameLevel?.level;

	const [flaggedFieldKey] = useSearchCustomFieldKeys(issueKeyOld, FLAGGED_CF_TYPE) || '';
	const [{ value: isIssueFlagged }] = useFlaggedField({
		issueKey: issueKeyOld,
		fieldKey: flaggedFieldKey,
	});

	const [prevIsIssueFlagged] = useState(isIssueFlagged);
	const [shouldAnnounce, setShouldAnnounce] = useState<boolean>(false);

	const onStatusSubmitted = (
		oldValue: StatusValue,
		newValue: StatusValue,
		meta: {
			transition: StatusTransition;
		},
		event: UIAnalyticsEvent,
	) => {
		workflowTransitionsActions.setTransitionInProgress(true);
		fireTrackAnalytics(event, 'issueStatus updated', {
			oldValId: oldValue.id,
			oldStatusCategoryId: oldValue.statusCategory.id,
			oldStatusCategoryName: oldValue.statusCategory.name,
			newValId: newValue.id,
			newStatusCategoryId: newValue.statusCategory.id,
			newStatusCategoryName: newValue.statusCategory.name,
			hasScreen: meta.transition.hasScreen,
			isConditional: meta.transition.isConditional,
			issueHierarchyLevel,
		});
	};

	const onStatusChangeFailure = useCallback(() => {
		workflowTransitionsActions.setTransitionInProgress(false);
	}, [workflowTransitionsActions]);

	useEffect(() => {
		experienceContext?.consumerCallbacks.onSuccess();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (
			(prevIsIssueFlagged === null && isIssueFlagged !== null) ||
			(prevIsIssueFlagged !== null && isIssueFlagged === null)
		) {
			setShouldAnnounce(true);
		}
	}, [isIssueFlagged, prevIsIssueFlagged]);

	let onStatusChangedWithAnalytics = noop;
	let onStatusChangeFailureWithAnalytics = noop;

	if (fg('ken-1009-add-editissue-task-analytics-to-status')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const issueKey = useIssueKey();
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const projectKey = useProjectKey(issueKey);
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const application = useApplication(projectKey, true);
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const edition = useEdition(projectKey, true);
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const analyticsSource = useAnalyticsSource();

		// eslint-disable-next-line react-hooks/rules-of-hooks
		onStatusChangedWithAnalytics = useCallback(
			(status: StatusDetails, viaDialog: boolean, event: UIAnalyticsEvent) => {
				onStatusChanged(status, viaDialog, event);
				sendExperienceAnalytics({
					wasExperienceSuccesful: true,
					action: TASK_SUCCESS,
					analyticsSource: analyticsSource ?? '',
					experience: 'editIssue5xx',
					application: application ?? null,
					edition: edition ?? null,
					additionalAttributes: {
						field: 'status',
					},
				});
			},
			[application, edition, onStatusChanged, analyticsSource],
		);

		// eslint-disable-next-line react-hooks/rules-of-hooks
		onStatusChangeFailureWithAnalytics = useCallback(
			(error: FetchError | undefined) => {
				onStatusChangeFailure();
				if (error?.statusCode && error?.statusCode >= 500 && error?.statusCode < 600) {
					sendExperienceAnalytics({
						wasExperienceSuccesful: false,
						action: TASK_FAIL,
						analyticsSource: analyticsSource ?? '',
						experience: 'editIssue5xx',
						application: application ?? null,
						edition: edition ?? null,
						additionalAttributes: {
							errorMessage: error?.message ?? '',
							field: 'status',
						},
					});
				} else if (error?.statusCode && error?.statusCode >= 400 && error?.statusCode < 500) {
					fireErrorAnalytics({
						error,
						meta: {
							id: 'issue.issue-view.editIssue',
							teamName: 'bento',
						},
						attributes: {
							experience: 'editIssue',
							analyticsSource: analyticsSource ?? '',
							application: application ?? null,
							edition: edition ?? null,
							wasExperienceSuccesful: false,
							field: 'status',
							hasEdit: true,
							traceId: error.traceId,
						},
					});
				}
			},
			[application, edition, onStatusChangeFailure, analyticsSource],
		);
	}

	const handleOnEditStart = useCallback(() => {
		if (!fg('one_event_rules_them_all_fg')) return;

		getUpdateAnalyticsFlowHelper().fireAnalyticsStart(StatusKey, {
			analytics: createAnalyticsEvent({}),
			attributes: {
				fieldType: StatusKey,
			},
		});
	}, [createAnalyticsEvent]);

	const isAiImproveIssuesEnabled =
		isPremium &&
		isAiEnabled &&
		(projectType === CORE_PROJECT || projectType === SOFTWARE_PROJECT) &&
		fg('jira-ai-issue-view-improve-issues-button');

	return (
		<ErrorBoundary
			onError={(location: string, error: Error) => {
				experienceContext?.consumerCallbacks.onFailure(STATUS_TYPE, {
					errorMessage: error.message,
				});
			}}
		>
			<Container>
				<UFOSegment name="issue-status-button">
					<JSErrorBoundary id="issue-status-button-rendered-error-boundary">
						<StatusFieldContainer
							// eslint-disable-next-line jira/integration/test-id-by-folder-structure
							data-testid="issue.views.issue-base.foundation.status.status-field-wrapper"
						>
							<StatusFieldWithRefWrapper
								fragmentKey={data}
								issueKey={issueKeyOld}
								projectType={projectType}
								initialValue={initialValue}
								isEditable={isEditable}
								isOptimistic={!fg('issue-view-status-transition-optimistic-update')}
								appearance="button"
								onSubmit={onStatusSubmitted}
								onSuccess={
									fg('ken-1009-add-editissue-task-analytics-to-status')
										? onStatusChangedWithAnalytics
										: onStatusChanged
								}
								onEditCancel={onEditCancel}
								onFailure={
									fg('ken-1009-add-editissue-task-analytics-to-status')
										? onStatusChangeFailureWithAnalytics
										: onStatusChangeFailure
								}
								preFetchTransitions
								registerInCommandPalette
								{...(fg('one_event_rules_them_all_fg') && {
									onEditStart: handleOnEditStart,
								})}
							/>
						</StatusFieldContainer>
					</JSErrorBoundary>
				</UFOSegment>
				{shouldAnnounce && (
					<ScreenReaderText role="status" aria-live="polite">
						{isIssueFlagged
							? formatMessage(messages.flagAdded)
							: formatMessage(messages.flagRemoved)}
					</ScreenReaderText>
				)}

				{isIssueFlagged && (
					<StatusComponentWrapper
						/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
						data-testid="issue.views.issue-base.foundation.status.flagged-field-wrapper"
					>
						<FlaggedField externalId="issue.status.flagged" />
					</StatusComponentWrapper>
				)}
				{fg('relay-migration-issue-fields-resolution') ? (
					<>
						{!transitionInProgress && shouldShowResolutionField && (
							<UFOSegment name="issue-resolution">
								<div
									// eslint-disable-next-line jira/integration/test-id-by-folder-structure
									data-testid="issue.views.issue-base.foundation.status.resolution"
								>
									<IssueViewResolutionField fragmentKey={data.resolutionField} />
								</div>
							</UFOSegment>
						)}
					</>
				) : (
					<>
						{!transitionInProgress &&
							((resolution !== undefined && resolution !== null) ||
								(resolutionFieldConfig?.isEditable &&
									initialValue?.statusCategory.id === categoryIdForStatusCategory(DONE))) && (
								<UFOSegment name="issue-resolution">
									<div
										// eslint-disable-next-line jira/integration/test-id-by-folder-structure
										data-testid="issue.views.issue-base.foundation.status.resolution"
									>
										<ResolutionField issueKey={issueKeyOld} />
									</div>
								</UFOSegment>
							)}
					</>
				)}
				{!transitionInProgress && (
					<UFOSegment name="issue-automation-button">
						<StatusComponentWrapper
							// eslint-disable-next-line jira/integration/test-id-by-folder-structure
							data-testid="issue.views.issue-base.foundation.status.actions-wrapper"
						>
							<ActionsMenu
								initialStatusValue={initialValue}
								onSubmit={onStatusSubmitted}
								onSuccess={
									fg('ken-1009-add-editissue-task-analytics-to-status')
										? onStatusChangedWithAnalytics
										: onStatusChanged
								}
							/>
						</StatusComponentWrapper>
					</UFOSegment>
				)}
				{projectType === SOFTWARE_PROJECT &&
					expValEquals('jira_issue_view_quick_approvals', 'isEnabled', true) && (
						<AsyncQuickApprovalsButton />
					)}
				{isAiImproveIssuesEnabled && (
					<UFOSegment name="improve-issue">
						<Flex
							// eslint-disable-next-line jira/integration/test-id-by-folder-structure
							testId="issue.views.issue-base.foundation.status.improve-issue"
							wrap="nowrap"
							xcss={aiImproveIssueParentStyles}
						>
							<ConnectedImproveIssueQuickAddItemWithStores />
						</Flex>
					</UFOSegment>
				)}
			</Container>
		</ErrorBoundary>
	);
};

// dummy field options that are not used anywhere, but they are required in the fieldSaveSuccess action.
export const fieldOptions: FieldOptions<unknown> = {
	canContainMediaContent: false,
	isOptimistic: false,
	isRichTextField: false,
	shouldSaveDraft: false,
	shouldDiscardChangeOnFailure: false,
	onSaveFailureFlagType: null,
	onSaveSuccessFlagType: null,
	saveField: () => Promise.resolve(),
};

// FIXME: JIV-17455 should be fully typed
export default flowWithSafeComponent(
	// @ts-expect-error - Argument of type 'InferableComponentEnhancerWithProps<StateProps & DispatchProps, {}>' is not assignable to parameter of type 'FlowStep<C, ComponentType<ComponentBeingTrackedProps<{ [k in keyof (DistributiveOmit<LibraryManagedAttributes<C, GetProps<C>>, Extract<"isEditable", keyof LibraryManagedAttributes<C, GetProps<C>>> | Extract<...> | Extract<...>> & {} & ConnectPropsMaybeWithoutContext<...>)]: (DistributiveOmit<...> & ... 1 more ... & ...'.
	connect(
		(state: State): StateProps => ({
			isEditable: isCompletedLoadingSelector(state),
		}),
		(dispatch: Dispatch<Action>): DispatchProps => ({
			onStatusChanged: (status, viaDialog) => {
				/* Ideally we would only dispatch fieldUpdated here, but there are still internal components listening to the changing
			   values that fieldSaveSuccess provides for status field. For now, let's fire both */
				// @ts-expect-error - TS2554 - Expected 4-5 arguments, but got 3.
				dispatch(fieldSaveSuccess(STATUS, fieldOptions, status));
				dispatch(fieldUpdated(STATUS, status, { viaDialog }));
			},
			onEditCancel: () => {
				/* TODO: this is being called twice when the transition dialog is opened and we close it by using the "escape" key.
			   This happens because the 'keydown' event is being handled by
			   the dialog itself *and* by the AK Select component. This is a bug on AK Select, since it
			   should remove that event listener when the component unmounts. I've raised a ticket
			   with Atlaskit to fix this: https://ecosystem.atlassian.net/projects/AK/queues/issue/AK-6310 */
				dispatch(fieldEditCancel(STATUS, fieldOptions));
			},
		}),
	), // TODO: Temporarily use analytic HoC until new hook based API is ready.
	withViewExperienceTracker(STATUS),
	withEditExperienceTracker(STATUS),
)(StatusView);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	flexWrap: 'wrap',
	paddingTop: token('space.150', '12px'),

	paddingBottom: 'unset',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'& > *:not(:last-child)': {
		marginRight: token('space.100', '8px'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StatusFieldContainer = styled.div({
	paddingRight: 'unset',
	paddingBottom: token('space.150', '12px'),
	overflow: 'hidden',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StatusComponentWrapper = styled.div({
	paddingBottom: token('space.150', '12px'),
	display: 'inline-flex',
	flexWrap: 'nowrap',
	maxWidth: '100%',
});

const aiImproveIssueParentStyles = xcss({ paddingBottom: 'space.150' });
