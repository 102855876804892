import React, { useCallback, type SyntheticEvent } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next/src';
import Button from '@atlaskit/button';
import { Flex, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import type { ProjectType } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { asyncInlineCreateStatusModal } from '@atlassian/jira-inline-create-status-modal/entrypoint.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { defaultModalProps } from '@atlassian/jira-issue-status-and-types-common/src/index.tsx';
import { ISSUE_VIEW_SOURCE } from '@atlassian/jira-issue-status-and-types-common/src/types.tsx';
import { ModalEntryPointPressableTrigger } from '@atlassian/jira-modal-entry-point-pressable-trigger/src/ModalEntryPointPressableTrigger.tsx';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { createStatusExperience } from '../../../experiences.tsx';
import { messages } from './messages.tsx';

export type CreateStatusButtonProps = {
	projectId?: string;
	projectKey: string;
	projectType: ProjectType | null;
	issueTypeId?: string;
	statusNames?: string[];
	onClick: () => void;
	onClose: () => void;
	onStatusCreated?: () => Promise<void>;
};

export const CreateStatusButton = ({
	projectId,
	projectKey,
	projectType,
	issueTypeId,
	statusNames = [],
	onClick,
	onClose,
	onStatusCreated,
}: CreateStatusButtonProps) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const cloudId = useCloudId();

	const handleClick = useCallback(
		(_e: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
			onClick();
			fireUIAnalytics(analyticsEvent, 'createStatusButton');
		},
		[onClick],
	);

	const onModalClose = useCallback(() => {
		onClose();
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'closed',
				actionSubject: 'inlineCreateStatusModal',
			}),
		);
	}, [createAnalyticsEvent, onClose]);

	if (!projectId || !projectType || !issueTypeId) {
		return null;
	}

	const entryPointParams = {
		cloudId,
		projectKey,
	};

	const entryPointProps = {
		statusNames,
		projectId,
		projectKey,
		projectType,
		onClose: onModalClose,
		isNewControllerEnabled: true,
		workflowAssociatedIssueTypeId: issueTypeId,
		createStatusExperience,
		source: ISSUE_VIEW_SOURCE,
		onStatusCreated,
	};

	return (
		<ModalEntryPointPressableTrigger
			entryPoint={asyncInlineCreateStatusModal}
			entryPointProps={entryPointProps}
			entryPointParams={entryPointParams}
			interactionName="create-status-modal"
			modalProps={defaultModalProps}
			useInternalModal
		>
			{({ ref }) => (
				<Flex xcss={flexContainerStyles}>
					<StyledButton
						appearance="subtle"
						shouldFitContainer
						interactionName="issue.fields.status.create-status.popup.open"
						onClick={handleClick}
						ref={ref}
					>
						{formatMessage(messages.createStatus)}
					</StyledButton>
				</Flex>
			)}
		</ModalEntryPointPressableTrigger>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const StyledButton = styled(Button)({
	alignItems: 'center',
	textAlign: 'left',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
	color: token('color.text', colors.N900),
	padding: `10px ${token('space.150', '12px')}`,
	height: '100%',
});

const flexContainerStyles = xcss({
	flexGrow: 1,
	height: token('space.500'),
});
