import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import {
	getPermalinkId,
	COMMENTS,
} from '@atlassian/jira-issue-fetch-services-common/src/common/utils/permalinks.tsx';

import type { ViewIssueData as IssueServiceViewIssueData } from '@atlassian/jira-issue-fetch-services/src/types.tsx';
import type {
	CommentsTransformResult,
	NormalizedUser,
	NormalizedComment,
} from '@atlassian/jira-issue-gira-transformer-types/src/common/types/comments.tsx';
import type { ViewIssueData } from '@atlassian/jira-issue-gira-transformer-types/src/common/types/server-data.tsx';
import type { TransformedGiraData } from '@atlassian/jira-issue-gira-transformer-types/src/common/types/transform-result.tsx';
import { NUM_INITIAL_ITEMS_TO_LOAD } from '@atlassian/jira-issue-view-common-constants/src/activity-feed.tsx';
import {
	transformComments,
	normalizeComments,
	normalizeGiraComments,
} from '../../comment-transformer.tsx';
import { getCommentsQuery } from './graphql.tsx';

const CREATED_ORDER_DESCENDING = '-created';

export const getQuery = () =>
	getCommentsQuery(NUM_INITIAL_ITEMS_TO_LOAD, 0, CREATED_ORDER_DESCENDING);

export const transformData = (
	issue: ViewIssueData | IssueServiceViewIssueData,
): CommentsTransformResult => {
	const { comments } = issue;

	if (!comments) {
		return {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
			users: {} as any,
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
			comments: {} as any,
			totalComments: 0,
			commentsStartIndex: 0,
			loadedComments: 0,
		};
	}

	const { totalCount: totalComments, startIndex: commentsStartIndex } = comments;

	const response = {
		data: {
			issueComments: comments,
		},
	};

	return {
		...normalizeComments(transformComments(response), 0, commentsStartIndex),
		totalComments,
		commentsStartIndex,
	};
};

export const transformGiraData = (
	issue: ViewIssueData | null,
	loadedComments: number,
	commentsStartIndex: number,
	type?: string,
	orderBy?: string,
): CommentsTransformResult => {
	const emptyComments = {
		users: {},
		comments: {},
		totalComments: 0,
		commentsStartIndex: 0,
		loadedComments: 0,
	};

	if (issue?.comments == null) {
		return emptyComments;
	}

	const { comments } = issue;
	const { totalCount: totalComments } = comments;
	const response = {
		data: {
			issueComments: comments,
		},
	};

	return {
		...normalizeGiraComments(
			transformComments(response),
			loadedComments,
			commentsStartIndex,
			type,
			orderBy,
		),
		totalComments,
	};
};

type LogFun = (attribute: string, giraString?: string, aggString?: string) => void;
type ExceptionMatched = (giraString?: string, aggString?: string) => boolean;
type Attribute = { [key: string]: ExceptionMatched | null };

const logError = (msg: string) =>
	log.safeErrorWithoutCustomerData('issue.agg-transformers.comments', msg);

const logResult = (isSuccess: boolean) =>
	log.safeInfoWithoutCustomerData('issue.agg-transformers.comments', 'result', { isSuccess });

const logCommentMismatch =
	(commentId: string) => (attribute: string, giraString?: string, aggString?: string) =>
		logError(
			`comment id ${commentId} mismatch for ${attribute}: gira: ${giraString} vs. agg: ${aggString}`,
		);

const commentAttributes: Attribute[] = [
	{
		authorId: (giraValue?: string, aggValue?: string) =>
			giraValue === 'unknown' || aggValue === 'unknown',
	},
	{ edited: null },
	{ id: null },
	{
		isInternal: (giraValue?: string, aggValue?: string) =>
			String(giraValue) === 'true' && String(aggValue) === 'false',
	},
	{
		updateAuthorId: (giraValue?: string, aggValue?: string) =>
			giraValue === 'unknown' || aggValue === 'unknown',
	},
	{ visibility: null },
	{ eventOccurredAt: null },
	{ jsdIncidentActivityViewHidden: null },
	{ jsdAuthorCanSeeRequest: (giraValue?: string, aggValue?: string) => aggValue === undefined },
];

const THIRTY_SECONDS = 30 * 10000;
export const compareComment = (
	giraCommentResult: TransformedGiraData,
	aggCommentResult: CommentsTransformResult,
) => {
	let isSuccess = true;
	try {
		const { users: aggUsers, comments: aggComments } = aggCommentResult;
		const { users: giraUsers, comments: giraComments } = giraCommentResult;

		if (!giraUsers || !giraComments || !aggUsers || !aggComments) {
			return;
		}

		const aggCommentIds = Object.keys(aggComments);
		const giraCommentIds = Object.keys(giraComments);

		if (JSON.stringify(aggCommentIds) !== JSON.stringify(giraCommentIds)) {
			if (getPermalinkId(COMMENTS) && aggCommentIds.length === 0) {
				logError('comments id mismatch due to targeted comment id not exist');
				return;
			}
			const difference = aggCommentIds
				.filter((commentId) => !giraCommentIds.includes(commentId))
				.concat(giraCommentIds.filter((commentId) => !aggCommentIds.includes(commentId)));
			const hasActualMismatch = difference.filter(
				(commentId) =>
					new Date().getTime() -
						new Date((aggComments[commentId] || giraComments[commentId]).createdDate).getTime() >
					THIRTY_SECONDS,
			);

			if (hasActualMismatch.length) {
				logError(`comments id mismatch agg: ${aggCommentIds} vs. gira: ${giraCommentIds}`);
				isSuccess = false;
				return;
			}
			if (!difference.length) {
				logError(`comments id order mismatch agg: ${aggCommentIds} vs. gira: ${giraCommentIds}`);
				isSuccess = false;
				return;
			}
		}

		Object.entries(giraComments).forEach(([commentId, giraCommentValue]) => {
			const aggCommentValue = aggComments[commentId];
			const attributeMatched = attributeComparison(
				commentAttributes,
				giraCommentValue,
				aggCommentValue,
				logCommentMismatch(commentId),
			);
			if (!attributeMatched) {
				isSuccess = false;
			}
		});
	} catch (error) {
		if (error instanceof Error) {
			logError(error.message);
		}
	} finally {
		logResult(isSuccess);
	}
};

const attributeComparison = (
	attributes: Attribute[],
	giraResponse: NormalizedComment | NormalizedUser,
	aggResponse: NormalizedComment | NormalizedUser,
	logFunc: LogFun,
) => {
	let matched = true;
	attributes.forEach((attribute) => {
		const field = Object.keys(attribute)[0];
		const exceptionMatched = attribute[field];
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- PLEASE FIX - ENABLING FLAT LINT CONFIG
		const giraValue = giraResponse[field as keyof typeof giraResponse];
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- PLEASE FIX - ENABLING FLAT LINT CONFIG
		const aggValue = aggResponse[field as keyof typeof aggResponse];
		if (
			((giraValue !== aggValue && typeof giraValue !== 'object') ||
				(typeof giraValue === 'object' &&
					JSON.stringify(giraValue) !== JSON.stringify(aggValue))) &&
			(!exceptionMatched || !exceptionMatched(giraValue, aggValue))
		) {
			logFunc(field, JSON.stringify(giraValue), JSON.stringify(aggValue));
			matched = false;
		}
	});
	return matched;
};
